const initState = {
    dashboardData: [],
    testimonialData: [],
    error: null
};
const HomeReducer = (state = initState, action) => {
    const data = action?.data;
    switch (action?.type) {
        case "HOME_DATA_REQUESTED":
            return {
                ...state,
            };
        case "HOME_DATA_RESPONSE":
            return {
                ...state,
                dashboardData: data,
            };
        case "HOME_DATA_Testimonial_RESPONSE":
            return {
                ...state,
                testimonialData: data,
            };
        case "HOME_ERROR":
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};
export default HomeReducer;
