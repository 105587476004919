import React, { useState, useEffect } from "react";
import DocumentMeta from "react-document-meta";
import { connect } from "react-redux";
import { corporateForm } from "../../redux/action/contact";
import { IMAGE_BASE_URL } from "../../redux/constant";
import { resetToast } from "../../redux/action/common";
import { ToastContainer, toast } from "react-toastify";
import Corporate from "./modal/Corporate";

const Services = ({ corporateForm, toastType, toastData, resetToast }) => {
  const [openModal, setOpenModal] = useState(false);
  useEffect(() => {
    if (toastType) {
      showToast();
      resetToast();
    }
  }, [toastType]);

  const showToast = () => {
    switch (toastType) {
      case "success":
        toast.success(toastData.message);
        break;
      case "error":
        toast.error(toastData.message);
        break;
      case "warning":
        toast.warning(toastData.message);
        break;
      default:
        break;
    }
  };

  const handleCorporate = (data) => {
    corporateForm(data);
  };

  const meta = {
    title:
      "News, Media Publishing, Advertising, Brand Digital Marketing, Content Solution - Digital Services @IDPL - IndiaDotCom Digital Pvt Ltd",
    description:
      "IDPL - IndiaDotcom Digital Pvt Ltd (formerly Zee Digital) is India’s one of the largest Digital Media Network. IDPL Digital Sevices including offers customised digital marketing solutions, sponsored content, native advertising, event sponsorship, targeted banner ads and more.",
    meta: {
      name: {
        keywords:
          "digital news, media publishing, online advertising, digital marketing, native ads, brand marketing, sponsored content, content solution, Zee Digital, IDPL services, IDPL, IndiaDotCom Digital Pvt Ltd",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <ToastContainer />
      {/* <!-- =============== Hero Section Start =============== --> */}
      <section className="hero-section-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 mx-auto">
              <h1 className="hero-title">
                <span>Digital Advertising & Sponsered</span>
                <span>Content Solutions</span>
              </h1>
              <div className="hero-sub-title">
                Through our platform, you can reach out to a diverse audience
                from everyday news consumers, <br />
                tech enthusiasts, and sports fanatics, to entertainment buffs and
                more.
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- =============== Hero Section End =============== --> */}

      {/* <!-- =============== Services Section Start =============== --> */}
      <section className="container services">
        <div className="row">
          <div className="col-md-6">
            <div className="idpl-heading-1">Native Advertising Solutions</div>
            <p className="mb-0">
              Our native advertising solution will help your brand reach the
              targeted audience. Helping brands and advertisers with the user
              base by following best of brand safety guidelines, and publishing
              in a more contextually relevant ecosystem.
            </p>
            <ul>
              <li>Branded Videos</li>
              <li>Branded Articles</li>
              <li>Influencer-led activity</li>
            </ul>
          </div>
          <div className="col-md-6 text-end">
            <img
              src={`${IMAGE_BASE_URL}/services/AdvertisingSolutions.svg`}
              alt="Advertising-Solutions"
              className="img-fluid"
            />
          </div>
        </div>
        <div className="row flex-column-reverse flex-md-row">
          <div className="col-md-6 text-start">
            <img
              src={`${IMAGE_BASE_URL}/services/InteractiveSolutions.svg`}
              alt="Interactive-Solutions"
              className="img-fluid"
            />
          </div>
          <div className="col-md-6">
            <div className="idpl-heading-1">
              Banners, Videos, and Interactive solutions
            </div>
            <p className="mb-0">
              Enhance your campaign and increase the longevity of your products
              and services exposure. Our digital presence is spread across 32
              most respected online news and information websites, which
              register millions of pageviews every month. Our expert team will
              help your brand explore innovative displays advertising solutions
              across digital, mobile & social platforms in the form of
              interactive ads:
            </p>
            <ul>
              <li>Display Banners</li>
              <li>Video Inventory on LIVE TV and VoD</li>
            </ul>
          </div>
        </div>
        <div className="row">
          <div className="col-md-6">
            <div className="idpl-heading-1">Sponsorship</div>
            <p className="mb-0">
            Our most celebrated Iconic brands: Zee news, Zee business, Bollywoodlife, Thehealthsite, BGR and others
             host some of the most exclusive events throughout the year. Our sponsorship 
             marketing team is an expert at matching the right partner to the right event to
              deliver best brand visibility. Spreading your brand across multiple events and channels 
              will allow your brand to reach more engaged and more targeted audiences. Our team could
               guide with insight-led decision-making.
            </p>
          </div>
          <div className="col-md-6 text-end">
            <img
              src={`${IMAGE_BASE_URL}/services/Sponsorship.svg`}
              alt="Sponsorship"
              className="img-fluid"
            />
          </div>
        </div>
      </section>
      {/* <!-- =============== Services Section End =============== --> */}

      {/* <!-- =============== Corporate-Advertise Section Start =============== --> */}
      <section className="CorporateAdvertise">
        <div className="container">
          <div className="row g-0">
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="CorporateSer">
                <div className="idpl-heading-1">
                  <span>Corporate </span>
                  <span>Presentation</span>
                </div>
                <p className="corAdv-para">Corporate Sales Presentation</p>
                <a
                  href="javascript:void();"
                  className="idpl-btn bg-2 w-auto px-5"
                  onClick={() => setOpenModal(true)}
                >
                  <span className="px-4">Download</span>
                </a>
              </div>
            </div>
            <div className="col-md-6 mb-4 mb-md-0">
              <div className="AdvertiseSer">
                <div className="idpl-heading-1">
                  <span>Advertise </span>
                  <span>with us</span>
                </div>
                <p className="corAdv-para">
                  To advertise on India's fastest-growing portal, contact:
                </p>
                <a href="mailto:sales@india.com" className="sales-email">
                  <img src={`${IMAGE_BASE_URL}/icons/email.svg`} alt="email" />
                  <span>Sales@India.com</span>
                </a>
              </div>
            </div>
          </div>
        </div>
      </section>
      {openModal ? (
        <Corporate
          openModal={openModal}
          handleCancel={(e) => setOpenModal(false)}
          corporateForm={corporateForm}
        />
      ) : null}
      {/* <!-- =============== Corporate-Advertise Section End =============== --> */}
    </DocumentMeta>
  );
};

const mapStateToProps = (state) => {
  const { CommonReducer } = state;
  return {
    toastData: CommonReducer.toastData,
    toastType: CommonReducer.toastType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    corporateForm: (data) => dispatch(corporateForm(data)),
    resetToast: () => dispatch(resetToast()),
  };
};
export default connect(mapStateToProps, mapDispatchToProps)(Services);
