import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import { Form, Input } from "antd";
import validator from "validator";

function Corporate({ openModal, handleCancel, corporateForm }) {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [office, setOffice] = useState("");
  const [emailError, setEmailError] = useState("");

  const onFinish = () => {
    const data = {
      name: name,
      mobile: mobile,
      email: email,
      office: office,
    };
    corporateForm(data);
  };

  return (
    <>
      <Modal
        show={openModal}
        onHide={handleCancel}
        className="idpl-modal"
        centered
        backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Fill the form</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="col-lg-12 col-md-12 ">
            <Form
              labelCol={{
                span: 8,
              }}
              wrapperCol={{
                span: 16,
              }}
              onFinish={onFinish}
            >
              <div className="form-wrp">
                <div className="idpl-form">
                  <Form.Item
                    label="Name"
                    name="name"
                    className="form-label"
                    rules={[
                      { required: true, message: "Please input your name!" },
                    ]}
                  >
                    <Input
                      type="text"
                      name="name"
                      value={name}
                      className="form-control"
                      placeholder="Eg: John Doe"
                      onChange={(e) => setName(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="idpl-form">
                  <Form.Item
                    label="Mobile Number"
                    name="mobile"
                    className="form-label"
                    rules={[
                      {
                        required: true,
                        message: "Please put  your mobile number!",
                      },
                    ]}
                  >
                    <Input
                      type="text"
                      name="mobile"
                      pattern="[0-9]*"
                      className="form-control"
                      placeholder="Eg: 991234XXXX"
                      maxLength={10}
                      value={mobile}
                      onChange={(e) =>
                        setMobile((v) =>
                          e.target.validity.valid ? e.target.value : v
                        )
                      }
                    />
                  </Form.Item>
                </div>
                <div className="idpl-form">
                  <Form.Item
                    label="Email"
                    name="email"
                    className="form-label"
                    rules={[
                      {
                        required: true,
                        message: "Please put  your email Address!",
                      },
                    ]}
                  >
                    <Input
                      type="email"
                      name="email"
                      nofreeemail={true}
                      className="form-control"
                      placeholder="Please add official email address"
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                  </Form.Item>
                </div>
                <div className="idpl-form">
                  <Form.Item
                    label="Organisation"
                    name="office"
                    className="form-label"
                    rules={[
                      { required: true, message: "Please put your office!" },
                    ]}
                  >
                    <Input
                      type="text"
                      name="office"
                      className="form-control"
                      placeholder="Eg: Noida"
                      value={office}
                      onChange={(e) => setOffice(e.target.value)}
                    />
                  </Form.Item>
                </div>

                <div className="idpl-form">
                  <button type="submit" className="idpl-form-btn">
                    Submit
                  </button>
                </div>
              </div>
            </Form>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Corporate;
