const initState = {
    jobData: [],
    searchJobData: [],
    jobDetailsData: [],
    error: null
};
const JobReducer = (state = initState, action) => {
    const data = action?.data;
    console.log('vishnu' + action.type);
    switch (action?.type) {
        case "Job_Data_REQUESTED":
            return {
                ...state,
            };
        case "Job_DATA_RESPONSE":
            return {
                ...state,
                jobData: data,
            };
        case "SEARCH_JOB_DATA_RESPONSE":
            return {
                ...state,
                searchJobData: data,
            };
        case "JOB_DETAILS_DATA_RESPONSE":
            return {
                ...state,
                jobDetailsData: data,
            };
        case "Job_ERROR":
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};
export default JobReducer;
