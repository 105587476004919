import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { connect } from "react-redux";
import { terms } from "../../redux/action/privacy";
import { parseHtml } from "../../utils/util";

const TermConditions = ({ terms, termData }) => {
  useEffect(() => {
    terms();
  }, []);

  const meta = {
    title: "Term & conditions @IDPL - IndiaDotCom Digital Pvt Ltd",
    description:
      "Term & conditions @IDPL - IndiaDotcom Digital Pvt Ltd (formerly Zee Digital) is one of the India’s No.1 Tech Led Digital Media Network. Please read these terms and conditions carefully as access and use of this site constitute acceptance of these terms & conditions.",
    meta: {
      name: {
        keywords:
          "Term & conditions, Website Term & conditions, Term & conditions IDPL, IDPL, IndiaDotCom Digital Pvt Ltd",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      {/* <!-- =============== Hero Section Start =============== --> */}
      <section className="hero-section-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 mx-auto">
              <h1 className="hero-title">Terms & Conditions</h1>
              <div className="hero-sub-title">Last Modified 24 Sep, 2022</div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- =============== Hero Section End =============== --> */}

      {/* <!-- =============== Content Section Start =============== --> */}
      <div className="container content-section">
        <div className="row">
          <div className="col-md-7 mx-auto">
            {termData.data && parseHtml(termData.data.description)}
          </div>
        </div>
      </div>
      {/* <!-- =============== Content Section End =============== --> */}
    </DocumentMeta>
  );
};

const mapStateToProps = (state) => {
  const { PrivacyReducer } = state;
  const { termData } = PrivacyReducer;
  return {
    termData: PrivacyReducer.termData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    terms: () => dispatch(terms()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TermConditions);
