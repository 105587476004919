import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { jobs, resumePostForm, searchJobAPI } from "../../redux/action/job";
import { IMAGE_BASE_URL } from "../../redux/constant";
import { parseHtml } from "../../utils/util";
import { Form, Input, message } from "antd";
import { resetToast } from "../../redux/action/common";
import { ToastContainer, toast } from "react-toastify";
import { WebRoutes } from "../../route";
import Pagination from "./common/Pagination";
import { paginate } from "./common/paginate";
import ScrollToTop from "../../user/components/ScrollToTop";

const OpenPositions = ({
  jobs,
  jobData,
  toastType,
  toastData,
  resetToast,
  resumePostForm,
  searchJobAPI,
  searchJobData,
}) => {
  const [jobLatestData, setJobLatestData] = useState([]);


  useEffect(() => {
    if (toastType) {
      showToast();
      resetToast();
    }
  }, [toastType]);

  useEffect(() => {
    console.log(searchJobData.data);
    setJobLatestData(searchJobData.data);
  }, [searchJobData]);

  const showToast = () => {
    switch (toastType) {
      case "success":
        toast.success(toastData.message);
        break;
      case "error":
        toast.error(toastData.message);
        break;
      case "warning":
        toast.warning(toastData.message);
        break;
      default:
        break;
    }
  };

  const [search, setSearch] = useState("");
  const [locationSearch, setLocationSearch] = useState("");
  const [departmentSearch, setDepartmentSearch] = useState("");
  const [positionSearch, setPositionSearch] = useState("");
  const [fname, setFName] = useState("");
  const [lname, setLName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [file, setFile] = useState("");
  const [posts, setPosts] = useState(jobData && jobData.data);
  const [totalPost, setTotalPost] = useState(jobData && jobData.data && jobData.data.length);
  const [currentPage, setCurrentPage] = useState(1);
  const [postPerPage] = useState(5);
  const [indexOfFirstPost, setIndexOfFirstPost] = useState(0);
  const [indexOfLastPost, setIndexOfLastPost] = useState(postPerPage);

 
  useEffect(() => {
    jobs();  
  }, []);
  const positions = jobData.data && [
    ...new Set(jobData.data.map((q) => q.title)),
  ];
  const department = jobData.data && [
    ...new Set(jobData.data.map((q) => q.department)),
  ];
  const location = jobData.data && [
    ...new Set(jobData.data.map((q) => q.location)),
  ];

  
  const [locationData, setLocationData] = useState(location && location[0]);
  const [departmentData, setDepartmentData] = useState(department && department[0]);
  const [positionData, setPositionData ] = useState(positions && positions[0]);

  const handleSearch = (e) => {
        const data = {
      search: search,
      location: locationSearch, position: positionSearch, department: departmentSearch
    };
    searchJobAPI(data);
  };

  const handleSearchLocation = (value) =>{
    setLocationSearch(value);
    const data = {
      search: search,
      location: value, position: positionSearch, department: departmentSearch
    };
    searchJobAPI(data);
  }

  const handleSearchDepartment = (value) =>{
    setDepartmentSearch(value);
    const data = {
      search: search,
      location: locationSearch, position: positionSearch, department: value
    };
    searchJobAPI(data);
  }

  const handleSearchPosition = (value) =>{
    setPositionSearch(value);
    const data = {
      search: search,
      location: locationSearch, position: value, department: departmentSearch
    };
    searchJobAPI(data);
  }
  const filterChange = () => {
    jobData.data &&
      jobData.data.filter((item) => {
        return item.location == locationSearch;
      });
  };
  

  const onFinish = () => {
    const data = {
      first_name: fname,
      last_name: lname,
      mobile: mobile,
      email: email,
      resume: file,
      location: locationData,
      position: positionData,
      department: departmentData,
    };
    let formData = new FormData();
    formData.append("first_name", fname);
    formData.append("last_name", lname);
    formData.append("mobile", mobile);
    formData.append("email", email);
    formData.append("resume", file);
    formData.append("location", locationData);
    formData.append("position", positionData);
    formData.append("department", departmentData);
    resumePostForm(formData);
  };

  const handleJobdescription = (id) => {
    localStorage.setItem('jobDescription', id);
    }

      //PAGINATION
      const pageNumbers = [];
      for (let i = 1; i <= Math.ceil(totalPost / postPerPage); i++) {
        pageNumbers.push(i);
      }

    const allPosts = paginate(jobData.data, currentPage, postPerPage);
    const handlePageChange = (page) => {
      // console.log(page);
      setCurrentPage(page);
    };

    function previousPage() {
      setCurrentPage(currentPage - 1);
    }
    function nextPage() {
      setCurrentPage(currentPage + 1);
    }

  
  return (
    <>
      <ToastContainer />
      {/* <!-- =============== Open Positions Start =============== --> */}
      <div className="container OpenPositionSection">
        <div className="row">
          <div className="col-lg-12 col-sm-12 mx-auto">
            <Link className="back-btn" to="/careers">
              <img
                src={`${IMAGE_BASE_URL}/icons/arrow-left-o.svg`}
                alt="icon"
              />
              <span>Back</span>
            </Link>
            <div className="idpl-heading-2 pt-3">Open Positions</div>
          </div>
        </div>
        <div className="row py-5">
          <div className="col-lg-3 col-md-3">
            <div className="idpl-form idpl-form-icon">
              <input
                type="email"
                className="form-control"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
                placeholder="Search Job..."
              />
              <img
                src={`${IMAGE_BASE_URL}/icons/search.svg`}
                alt="icon"
                className="form-icon"
                onClick={(e) => handleSearch()}
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <div className="idpl-form idpl-form-icon">
              <select
                className="form-control"
                placeholder="Location"
                value={locationSearch}
                onChange={(e) =>  handleSearchLocation(e.target.value)}
              >
                <option readonly hidden>
                  Select Location
                </option>
                {location &&
                  location.map((location) =>
                   <option value={location}>{location}</option>)}
              </select>
              <img
                src={`${IMAGE_BASE_URL}/icons/chevron-down.svg`}
                alt="icon"
                className="form-icon"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <div className="idpl-form idpl-form-icon">
              <select
                className="form-control"
                placeholder="Departments"
                value={departmentSearch}
                onChange={(e) => handleSearchDepartment(e.target.value)}
              >
                <option readonly hidden>
                  Select Departments
                </option>
                {department &&
                  department.map((department) => (
                    <option value={department}>{department}</option>
                  ))}
              </select>
              <img
                src={`${IMAGE_BASE_URL}/icons/chevron-down.svg`}
                alt="icon"
                className="form-icon"
              />
            </div>
          </div>
          <div className="col-lg-3 col-md-3">
            <div className="idpl-form idpl-form-icon">
              <select
                className="form-control"
                placeholder="Positions"
                value={positionSearch}
                onChange={(e) => handleSearchPosition(e.target.value)}
              >
                <option readonly hidden>
                  Select Positions
                </option>
                {positions &&
                  positions.map((position) => <option value={position}>{position}</option>)}
              </select>
              <img
                src={`${IMAGE_BASE_URL}/icons/chevron-down.svg`}
                alt="icon"
                className="form-icon"
              />
            </div>
          </div>
        </div>
      </div>
      {/* <!-- =============== Open Positions End =============== --> */}

      {/* <!-- =============== All Jobs Start =============== --> */}
      <section className="container">
        <div className="row">
          <div className="col-md-12">
            {jobLatestData && jobLatestData.length > 0
              ? jobLatestData.map((item) => (
                <div className="jobPost">
                  <div className="jobPostTtl">{item.title}</div>
                  <p>{parseHtml(item.description)}</p>
                  <ul>
                    <li>
                      <img
                        src={`${IMAGE_BASE_URL}/icons/fullTime-2.svg`}
                        alt="icon"
                      />
                      <span>{item.role}</span>
                    </li>
                    <li>
                      <img
                        src={`${IMAGE_BASE_URL}/icons/location-2.svg`}
                        alt="icon"
                      />
                      <span>{item.location}</span>
                    </li>
                    <li className="designation">
                      <span>{item.department}</span>
                    </li>
                  </ul>
                  {item.status ? (
                    <Link
                      to={`/jobDescription/${item.id}`}
                      className="JobApplyBtn"
                    >
                      Apply
                    </Link>
                  ) : (
                    <Link to="" className="JobClosedBtn">
                      Closed Position
                    </Link>
                  )}
                </div>
              ))
              :
               allPosts &&
              allPosts.map((item) => (
                <div className="jobPost">
                  <div className="jobPostTtl">{item.title}</div>
                  <p>{parseHtml(item.description)}</p>
                  <ul>
                    <li>
                      <img
                        src={`${IMAGE_BASE_URL}/icons/fullTime-2.svg`}
                        alt="icon"
                      />
                      <span>{item.role}</span>
                    </li>
                    <li>
                      <img
                        src={`${IMAGE_BASE_URL}/icons/location-2.svg`}
                        alt="icon"
                      />
                      <span>{item.location}</span>
                    </li>
                    <li className="designation">
                      <span>{item.department}</span>
                    </li>
                  </ul>
                  {item.status ? (
                    <Link
                      to={WebRoutes.JOBDESCRIPTION}
                      className="JobApplyBtn"
                      onClick={(e) => handleJobdescription(item.id)}
                    >
                      Apply
                    </Link>
                  ) : (
                    <Link to="" className="JobClosedBtn">
                      Closed Position
                    </Link>
                  )}
                </div>
              ))}
              
          </div>
          <div className="col-md-12 my-5">
          <Pagination
           itemsCount={jobData && jobData.data && jobData.data.length} 
            pageSize={postPerPage}
            onPageChange={handlePageChange} 
            OnPreviousPage={previousPage} 
            OnNextPage={nextPage} 
            currentPage={currentPage} 
            setCurrentPage={setCurrentPage} />
          </div>
        </div>
      </section>
      {/* <!-- =============== All Jobs End =============== --> */}

      {/* <!-- =============== Share Your Resume Start =============== --> */}
      <section className="container py-4">
        <div className="row">
          <div className="col-md-12">
            <div className="resumeHead">Share Your Resume</div>
            <div className="resumePara">
              If you don’t find the suitable opening then you can share resume
              with us.
            </div>
          </div>
          <div className="col-md-12">
            <div className="resumeForm">
              <div className="row">
                <div className="col-lg-8 col-md-8">
                  <Form
                    labelCol={{
                      span: 8,
                    }}
                    wrapperCol={{
                      span: 16,
                    }}
                    onFinish={onFinish}
                  >
                    <div className="row">
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="idpl-form">
                          <Form.Item
                            label="First Name"
                            name="fname"
                            className="form-label"
                            rules={[
                              {
                                required: true,
                                message: "Please input your first name!",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              className="form-control text-capitalize"
                              placeholder="First Name"
                              value={fname}
                              onChange={(e) => setFName(e.target.value)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="idpl-form">
                          <Form.Item
                            label="Last Name"
                            name="lname"
                            className="form-label"
                            rules={[
                              {
                                required: true,
                                message: "Please input your last name!",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              className="form-control text-capitalize"
                              placeholder="Last Name"
                              value={lname}
                              onChange={(e) => setLName(e.target.value)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="idpl-form">
                          <Form.Item
                            label="E-mail Address"
                            name="email"
                            className="form-label"
                            rules={[
                              {
                                required: true,
                                type: "email",
                                message: "Please input your Email!",
                              },
                            ]}
                          >
                            <Input
                              type="email"
                              className="form-control"
                              placeholder="@example.com"
                              value={email}
                              onChange={(e) => setEmail(e.target.value)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="idpl-form">
                          <Form.Item
                            label="Mobile Number"
                            name="mobile"
                            className="form-label"
                            rules={[
                              {
                                required: true,
                                message: "Please input your mobile number!",
                              },
                            ]}
                          >
                            <Input
                              type="text"
                              className="form-control"
                              placeholder="9911XXXXXX"
                              value={mobile}
                              onChange={(e) => setMobile(e.target.value)}
                            />
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="idpl-form">
                          <Form.Item
                            label="Department"
                            name="department"
                            className="form-label"
                            rules={[
                              {
                                required: true,
                                message: "Please select department!",
                              },
                            ]}
                          >
                            <select
                              className="form-control"
                              onChange={(e) =>
                                setDepartmentData(e.target.value)
                              }
                            >
                              <option readonly hidden>
                                Select Departments
                              </option>
                              {department &&
                                department.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                            </select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="idpl-form">
                          <Form.Item
                            label="Location"
                            name="location"
                            className="form-label"
                            rules={[
                              {
                                required: true,
                                message: "Please select location!",
                              },
                            ]}
                          >
                            <select
                              className="form-control"
                              onClick={(e) => setLocationData(e.target.value)}
                            >
                              <option readonly hidden>
                                Select Location
                              </option>
                              {location &&
                                location.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                            </select>
                          </Form.Item>
                        </div>
                      </div>
                      <div className="col-lg-6 col-md-6 col-sm-12">
                        <div className="idpl-form">
                          <Form.Item
                            label="Position"
                            name="mobile"
                            className="form-label"
                            rules={[
                              {
                                required: true,
                                message: "Please select position!",
                              },
                            ]}
                          >
                            <select
                              className="form-control"
                              placeholder="Positions"
                              onChange={(e) => setPositionData(e.target.value)}
                            >
                              <option readonly hidden>
                                Select Positions
                              </option>
                              {positions &&
                                positions.map((item) => (
                                  <option value={item}>{item}</option>
                                ))}
                            </select>
                          </Form.Item>
                        </div>
                      </div>

                      <div className="col-lg-12 col-md-12 col-sm-12 mt-4">
                        <div className="resumeHEAD">Resume/CV</div>
                        <label
                          htmlFor="resume"
                          className="idpl-btn-linear w-auto"
                        >
                          <span>Attach File</span>
                        </label>
                        <Form.Item
                          name="resume"
                          className="antInputFile"
                          rules={[
                            {
                              required: true,
                              message: "Please attach your resume!",
                            },
                          ]}
                        >
                          <input
                            type="file"
                            value={file}
                          
                            onChange={(e) => setFile(e.target.files[0])}
                            accept=".doc, .docx, .txt, .pdf"
                          // id="attachFile"
                          />
                        </Form.Item>
                        <span className="attachedFileName">{file && file.name}</span>
                        <div className="resumePARA mt-3">
                          Attach
                          <a href="#" target="_blank">
                            Dropbox,
                          </a>
                          <a href="#" target="_blank">
                            Google Drive
                          </a>
                          or enter manually
                        </div>
                        <div className="resumePARA">
                          (File types: pdf, doc, docx, txt, rtf)
                        </div>
                      </div>
                      
                      <div className="col-lg-12 col-md-12 col-sm-12">
                        <button type="submit" className="idpl-btn mt-4">
                          <span>Submit</span>
                        </button>
                      </div>
                    </div>
                  </Form>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section >
      {/* <!-- =============== Share Your Resume End =============== --> */}
    </>
  );
};
const mapStateToProps = (state) => {
  const { JobReducer, CommonReducer } = state;
  return {
    jobData: JobReducer.jobData,
    searchJobData: JobReducer.searchJobData,
    toastData: CommonReducer.toastData,
    toastType: CommonReducer.toastType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    jobs: () => dispatch(jobs()),
    resetToast: () => dispatch(resetToast()),
    resumePostForm: (data) => dispatch(resumePostForm(data)),
    searchJobAPI: (data) => dispatch(searchJobAPI(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(OpenPositions);
