import axios from "axios";
import { PRODUCTLIST } from "../constant";
import { getCommonApiHeader } from "../../utils/util";



export const product = (data) => {
    return (dispatch, getState) => {
        dispatch(getProductDataRequest());
        axios
            .get(PRODUCTLIST, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getPoductDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}


export const getProductDataRequest = data => {
    return {
        type: "Product_Data_REQUESTED",
    };
};

export const getPoductDataRespond = data => {
    return {
        type: "Product_DATA_RESPONSE",
        data: data,
    };
};

export const handleError = error => {
    return {
        type: "Product_ERROR",
        error,
    };
};
