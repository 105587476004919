const initState = {
    productData: [],
    error: null
};
const ProductReducer = (state = initState, action) => {
    const data = action?.data;
    switch (action?.type) {
        case "Product_Data_REQUESTED":
            return {
                ...state,
            };
        case "Product_DATA_RESPONSE":
            return {
                ...state,
                productData: data,
            };
        case "Product_ERROR":
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};
export default ProductReducer;
