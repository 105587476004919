import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { jobs, appyJobAPI, jobDetailsAPI } from "../../redux/action/job";
import { IMAGE_BASE_URL } from "../../redux/constant";
import { WebRoutes } from "../../route";
import { parseHtml } from "../../utils/util";
import ApplyNow from "./modal/ApplyNow";

import Share from "./modal/Share";

const JobShare = ({ jobs, jobData, appyJobAPI, jobDetailsAPI, jobDetailsData }) => {
    const [openModal, setOpenModal] = useState(false);
    const [openShare, setOpenShare] = useState(false);

    const { id } = useParams();
    useEffect(() => {
        jobs();
    }, []);

    useEffect(() => {
        jobDetailsAPI(id)
    }, []);

    console.log(jobDetailsData)





    return (
        <>
            {/* <!-- =============== Job Description Header Start =============== --> */}
            <div className="container JobDescriptionHead">
                <div className="row">
                    <div className="col-lg-12 col-sm-12 mx-auto">
                        <Link to={WebRoutes.OPENPOSITION} className="allJobs">
                            View All Jobs
                        </Link>
                        <>
                            <div className="jobHead">{jobDetailsData && jobDetailsData.data && jobDetailsData.data.title}</div>
                            <div className="jobPara">
                                {parseHtml(jobDetailsData && jobDetailsData.data && jobDetailsData.data.description)}
                            </div>
                            <ul>
                                <li>
                                    <img src={`${IMAGE_BASE_URL}/icons/fullTime.svg`} alt="icon" />
                                    <span>{jobDetailsData && jobDetailsData.data && jobDetailsData.data.role}</span>
                                </li>
                                <li>
                                    <img src={`${IMAGE_BASE_URL}/icons/location.svg`} alt="icon" />
                                    <span>{jobDetailsData && jobDetailsData.data && jobDetailsData.data.location}</span>
                                </li>
                                <li>
                                    <div to="" className="jobttl">
                                        {jobDetailsData && jobDetailsData.data && jobDetailsData.data.department}
                                    </div>
                                </li>
                            </ul></>
                    </div>
                </div>
            </div>
            {/* <!-- =============== Job Description Header End =============== --> */}

            {/* <!-- =============== Job Description Start =============== --> */}
            <section className="container JobDescriptionSec">
                <div className="row">
                    <div className="col-md-11 mx-auto">
                        <div className="row">
                            <div className="col-lg-9 col-md-9">
                                <div className="jd-head">Be a part of India’s largest and most admired news & infotainment network!</div>
                                <div className="jd-para">
                                    Indiadotcom Digital Private Limited (IDPL) is India's most diversified digital media conglomerate, with news and infotainment brands that touch the lives of millions every day. Our organization works to maintain its status as a young, flexible, and energetic brand despite its decade-long legacy, size, and distribution.
                                </div>
                                <div className="jd-para">
                                    We believe in hiring the best talent and assisting them in reaching their full potential. In order to reach new heights as a team, we encourage our people to think big and innovate with efficiency to deliver great results. We look forward to you joining us as "intrapreneurs" to scale even greater heights together as we reach more people, create better experiences, and infuse entrepreneurial thinking into your company culture.
                                </div>


                                <div className="jd-head">Benefits</div>
                                <ul className="jd-list">
                                    <li>
                                        We offer an array of work opportunities across digital, broadcasting and publishing space.
                                    </li>
                                    <li>
                                        A chance to work in the fastest growing digital media organization
                                    </li>
                                    <li>
                                        Provides abundant exciting and challenging work opportunities that will accelerate your learning curve.


                                    </li>
                                    <li>
                                        Freedom to innovate, experiment and make your own decisions.
                                    </li>
                                    <li>An opportunity to hone your skills through best-in- class exposure and experiences</li>

                                </ul>
                            </div>

                            <div className="col-lg-3 col-md-3  text-md-start text-center mt-5 mt-md-0">
                                <a href="javascript:void();" className="idpl-btn" onClick={() => setOpenModal(true)}>
                                    <span>Apply Job</span>
                                </a>
                                <a href="javascript:void();" className="idpl-btn-linear mt-4" onClick={() => setOpenShare(true)}>
                                    <img src={`${IMAGE_BASE_URL}/icons/share.svg`} alt="icon" className="me-2" />
                                    <span >Share Job</span>
                                </a>


                            </div>
                        </div>
                    </div>
                </div>
            </section>

            {openModal ?
                <ApplyNow openModal={openModal} handleCancel={(e) => setOpenModal(false)} appyJobAPI={appyJobAPI} jobDetailsData={jobDetailsData} />
                : null
            }

            {openShare ?
                <Share openShare={openShare} handleCancel={(e) => setOpenShare(false)} jobDetailsData={jobDetailsData} />
                : null
            }
            {/* <!-- =============== Job Description End =============== --> */}
        </>
    );
};
const mapStateToProps = (state) => {
    const { JobReducer } = state;
    return {
        jobData: JobReducer.jobData,
        jobDetailsData: JobReducer.jobDetailsData,
    };
};

const mapDispatchToProps = (dispatch) => {
    return {
        jobs: () => dispatch(jobs()),
        appyJobAPI: (data) => dispatch(appyJobAPI(data)),
        jobDetailsAPI: (data) => dispatch(jobDetailsAPI(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(JobShare);
