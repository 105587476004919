import { combineReducers } from "redux";

// user reducer
import HomeReducer from './HomeReducer';
import AboutReducer from './AboutReducer';
import PrivacyReducer from "./PrivacyReducer";
import ContactReducer from "./ContactReducer";
import ProductReducer from "./ProductReducer";
import CommonReducer from './Common';
import JobReducer from "./JobReducer";



//

const userReducers = {
    HomeReducer,
    AboutReducer,
    PrivacyReducer,
    ContactReducer,
    ProductReducer,
    CommonReducer,
    JobReducer

}


const rootReducer = combineReducers({
    ...userReducers
});

export default rootReducer;