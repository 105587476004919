import React from "react";
import _ from "lodash";

const Pagination = (props) => {
  const { itemsCount, pageSize, currentPage, OnPreviousPage, OnNextPage, onPageChange } = props;

  const pagesCount = Math.ceil(itemsCount / pageSize);
  if (pagesCount === 1) return null;
  const pages = _.range(1, pagesCount + 1);

  console.log(pagesCount);


  return (
    <nav className="sach-pagination-round">
      <ul className="pagination">
        <li className={currentPage === 1 ? "page-item disabled" : "page-item"}>
          <a className="page-link" onClick={() => OnPreviousPage()}>
          <svg width="24" height="25" viewBox="0 0 24 25" fill="#d9562a" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.3625 17.1467C13.1005 17.1467 12.8395 17.0447 12.6435 16.8417L8.78053 12.8417C8.40253 12.4487 8.40753 11.8257 8.79353 11.4397L12.7935 7.43973C13.1835 7.04873 13.8165 7.04873 14.2075 7.43973C14.5975 7.83073 14.5975 8.46273 14.2075 8.85373L10.9025 12.1587L14.0815 15.4517C14.4655 15.8497 14.4545 16.4827 14.0575 16.8657C13.8625 17.0537 13.6125 17.1467 13.3625 17.1467Z" /></svg>
          </a>
        </li>
        {pages.map((page) => (
          <li className="page-item" key={page}>
            <a className={page === currentPage ? "page-link active" : "page-link"} onClick={() => onPageChange(page)}>
              {page}
            </a>
          </li>
        ))}
        <li className={currentPage === pagesCount ? "page-item disabled" : "page-item"}>
          <a className="page-link" onClick={() => OnNextPage()}>
          <svg width="24" height="25" viewBox="0 0 24 25" fill="#d9562a" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.4998 17.1458C10.2438 17.1458 9.98776 17.0478 9.79276 16.8528C9.40176 16.4618 9.40176 15.8298 9.79276 15.4388L13.0978 12.1338L9.91776 8.84076C9.53476 8.44276 9.54576 7.80976 9.94276 7.42676C10.3408 7.04376 10.9738 7.05476 11.3568 7.45076L15.2188 11.4508C15.5978 11.8438 15.5928 12.4668 15.2068 12.8528L11.2068 16.8528C11.0118 17.0478 10.7558 17.1458 10.4998 17.1458Z"/></svg>
          </a>
        </li>
      </ul>
    </nav>
  );
};

export default Pagination;
