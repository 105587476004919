const initState = {
    privacyData: [],
    termData: [],
    error: null
};
const PrivacyReducer = (state = initState, action) => {
    const data = action?.data;
    switch (action?.type) {
        case "Privacy_Data_REQUESTED":
            return {
                ...state,
            };
        case "PRIVACY_DATA_RESPONSE":
            return {
                ...state,
                privacyData: data,
            };
        case "Terms_Data_Team_RESPONSE":
            return {
                ...state,
                termData: data,
            };
        case "Privacy_ERROR":
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};
export default PrivacyReducer;
