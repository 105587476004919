import React, { useEffect } from "react";
import DocumentMeta from "react-document-meta";
import { connect } from "react-redux";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  userCount,
  testimonials,
  emailSubscriptionApi,
} from "../../redux/action/home";
import { Link } from "react-router-dom";
import { product } from "../../redux/action/product";
import { parseHtml } from "../../utils/util";
import { IMAGE_BASE_URL } from "../../redux/constant";
import { Form, Input, message } from "antd";
import { useState } from "react";
import { resetToast } from "../../redux/action/common";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { WebRoutes } from "../../route";

const Home = ({
  dashboardData,
  userCount,
  testimonials,
  testimonialData,
  product,
  productData,
  emailSubscriptionApi,
  toastType,
  toastData,
  resetToast,
}) => {
  const homeHeroCarousel = {
    loop: true,
    items: 1,
    animateIn: "animate__zoomIn",
    animateOut: "animate__zoomOut",
    autoplayTimeout: 5000,
    autoplay: true,
  };

  const testimonial = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    animateOut: "fadeOut",
    autoplay: true,
    navText: [
      '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <g opacity="0.5"> <path fill-rule="evenodd" clip-rule="evenodd" d="M27 18.9999H15.135L18.768 14.6399C19.122 14.2159 19.064 13.5849 18.64 13.2319C18.215 12.8779 17.585 12.9359 17.232 13.3599L12.232 19.3599C12.193 19.4069 12.173 19.4619 12.144 19.5139C12.12 19.5559 12.091 19.5919 12.073 19.6379C12.028 19.7529 12.001 19.8739 12.001 19.9959C12.001 19.9969 12 19.9989 12 19.9999C12 20.0009 12.001 20.0029 12.001 20.0039C12.001 20.1259 12.028 20.2469 12.073 20.3619C12.091 20.4079 12.12 20.4439 12.144 20.4859C12.173 20.5379 12.193 20.5929 12.232 20.6399L17.232 26.6399C17.43 26.8769 17.714 26.9999 18 26.9999C18.226 26.9999 18.453 26.9239 18.64 26.7679C19.064 26.4149 19.122 25.7839 18.768 25.3599L15.135 20.9999H27C27.552 20.9999 28 20.5519 28 19.9999C28 19.4479 27.552 18.9999 27 18.9999" fill="white"/> <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="white"/> </g> </svg>',
      '<svg width="40" height="40" viewBox="0 0 40 40" fill="none" xmlns="http://www.w3.org/2000/svg"> <g opacity="0.5"> <path fill-rule="evenodd" clip-rule="evenodd" d="M13 21H24.865L21.232 25.36C20.878 25.784 20.936 26.415 21.36 26.768C21.785 27.122 22.415 27.064 22.769 26.64L27.769 20.64C27.808 20.593 27.827 20.538 27.856 20.486C27.88 20.444 27.909 20.408 27.927 20.362C27.972 20.247 27.999 20.126 27.999 20.004C27.999 20.003 28 20.001 28 20C28 19.999 27.999 19.997 27.999 19.996C27.999 19.874 27.972 19.753 27.927 19.638C27.909 19.592 27.88 19.556 27.856 19.514C27.827 19.462 27.808 19.407 27.769 19.36L22.769 13.36C22.57 13.123 22.286 13 22 13C21.774 13 21.547 13.076 21.36 13.232C20.936 13.585 20.878 14.216 21.232 14.64L24.865 19H13C12.448 19 12 19.448 12 20C12 20.552 12.448 21 13 21Z" fill="white"/> <rect x="0.5" y="0.5" width="39" height="39" rx="19.5" stroke="white"/> </g> </svg>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };

  const digitalEcosystem = {
    loop: true,
    margin: 10,
    nav: true,
    dots: false,
    autoplay: true,
    animateOut: "fadeOut",
    margin: 30,
    navText: [
      '<svg width="24" height="25" viewBox="0 0 24 25" fill="#D9562A" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.3625 17.1467C13.1005 17.1467 12.8395 17.0447 12.6435 16.8417L8.78053 12.8417C8.40253 12.4487 8.40753 11.8257 8.79353 11.4397L12.7935 7.43973C13.1835 7.04873 13.8165 7.04873 14.2075 7.43973C14.5975 7.83073 14.5975 8.46273 14.2075 8.85373L10.9025 12.1587L14.0815 15.4517C14.4655 15.8497 14.4545 16.4827 14.0575 16.8657C13.8625 17.0537 13.6125 17.1467 13.3625 17.1467Z" /></svg>',
      '<svg width="24" height="25" viewBox="0 0 24 25" fill="#D9562A" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.4998 17.1458C10.2438 17.1458 9.98776 17.0478 9.79276 16.8528C9.40176 16.4618 9.40176 15.8298 9.79276 15.4388L13.0978 12.1338L9.91776 8.84076C9.53476 8.44276 9.54576 7.80976 9.94276 7.42676C10.3408 7.04376 10.9738 7.05476 11.3568 7.45076L15.2188 11.4508C15.5978 11.8438 15.5928 12.4668 15.2068 12.8528L11.2068 16.8528C11.0118 17.0478 10.7558 17.1458 10.4998 17.1458Z"/></svg>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 2,
      },
      1000: {
        items: 3,
      },
    },
  };

  useEffect(() => {
    userCount();
    testimonials();
    product();
  }, []);
  const [email, setEmail] = useState();

  useEffect(() => {
    if (toastType) {
      showToast();
      resetToast();
    }
  }, [toastType]);

  const showToast = () => {
    switch (toastType) {
      case "success":
        toast.success(toastData.message);
        break;
      case "error":
        toast.error(toastData.message);
        break;
      case "warning":
        toast.warning(toastData.message);
        break;
      default:
        break;
    }
  };
  const onFinish = () => {
    const data = {
      email: email,
    };
    emailSubscriptionApi(data);
    setEmail("");
  };
  return (
    <>
      <ToastContainer />
      <DocumentMeta>
        {/* <!-- =============== Hero Section Start =============== --> */}
        <section className="hero-section">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12 mx-auto">
                <OwlCarousel
                  className="owl-carousel owl-theme"
                  id="homeHeroCarousel"
                  {...homeHeroCarousel}
                >
                  <div className="item">
                    <h1 className="hero-title">
                      <span>Unleash The Power of </span>
                      <span>Infinite Possibilities </span>
                      <span>With IDPL</span>
                    </h1>
                  </div>
                  <div className="item">
                    <h1 className="hero-title">
                      <span>We Are India's Leading </span>
                      <span>& Most Diversified </span>
                      <span>Digital Media Company </span>
                    </h1>
                  </div>
                  <div className="item">
                    <h1 className="hero-title">
                      <span>Trusted By Over </span>
                      <span>345 Million Digital </span>
                      <span>Users & Counting</span>
                    </h1>
                  </div>
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- =============== Hero Section End =============== --> */}

        {/* <!-- =============== Infinite Potential Section Start =============== --> */}
        <div className="container my-5 pt-5">
          <div className="row">
            <div className="col-lg-6 col-sm-12 mx-auto">
              <h2 className="idpl-heading-1 mb-3">Our Infinite Potential</h2>
              <p className="idpl-para-1 mb-4">
                IndiaDotcom Digital Private Limited (IDPL) is one of the largest
                digital media publishing company in India, registering more than
                <b> 1399 million pageviews per month.</b> IDPL’s distinctive digital products are amongst the fastest-growing websites and
                applications worldwide. With over 32 digital properties,
                including Zee News, Zee Business, Wion, Zee Hindustan, DNA and
                more, we deliver unbiased and verified content spanning across
                all genres, which is trusted by users across the globe.
              </p>
              <p className="idpl-para-1">
                Today, we are one of the largest and most dynamic hyperlocal media conglomerates. A multi-platform destination for news, opinions, financial data and infotainment for the evolved digital news consumer. We uphold the highest standards of journalism by providing our audience with factual news fearlessly on the go.
              </p>
            </div>
            <div className="col-lg-6 col-sm-6 mx-auto">
              <img
                src={`${IMAGE_BASE_URL}/InfinitePotential.svg`}
                alt="Infinite Potential"
                className="img-fluid"
              />
            </div>
          </div>
        </div>
        {/* <!-- =============== Infinite Potential Section End =============== --> */}

        {/* <!-- =============== Key Metrics Section Start =============== --> */}
        <section className="keyMetrics">
          <div className="container">
            <div className="row">
              <div className="col-lg-9 col-sm-12 mx-auto">
                <h2 className="idpl-heading-1 mb-3">
                  Key Metrics Of Our Robust Brand Engagement
                </h2>
                <p className="idpl-para-1 mb-4">
                  Engaging and empowering our global audience with real-time news. Our iconic flagship brands are optimized to operate across a wide variety of platforms, simplifying user accessibility. We aim to deliver well-researched news curated by influential journalists who are experts in the domain. IDPL is a one-stop destination for all curious minds.
                </p>

                <div className="row">
                  <div className="col-md-4 col-sm-12">
                    <div className="keyMetricsItem">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/click.svg`}
                        alt="icon"
                      />
                      <b>{dashboardData.data?.website_user}+ Million</b>
                      <span>Website User Per Month</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="keyMetricsItem">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/view.svg`}
                        alt="icon"
                      />
                      <b>{dashboardData.data?.page_views}+ Million</b>
                      <span>Page Views Per Month</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="keyMetricsItem">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/video.svg`}
                        alt="icon"
                      />
                      <b>{dashboardData.data?.website_user}+ Million</b>
                      <span>Website Video Views Per Month</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="keyMetricsItem">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/subscribers.svg`}
                        alt="icon"
                      />
                      <b>{dashboardData.data?.youtube_subscriber}+ Million</b>
                      <span>Youtube Subscribers</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="keyMetricsItem">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/youtubeVideo.svg`}
                        alt="icon"
                      />
                      <b>{dashboardData.data?.youtube_video}+ Billion</b>
                      <span>Youtube Video Views</span>
                    </div>
                  </div>
                  <div className="col-md-4 col-sm-12">
                    <div className="keyMetricsItem">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/followers.svg`}
                        alt="icon"
                      />
                      <b>{dashboardData.data?.social_followers}+ Million</b>
                      <span>Social Followers</span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- =============== Key Metrics Section End =============== --> */}

        {/* <!-- =============== Value We Deliver Section Start =============== --> */}
        <section className="ValueWeDeliver">
          <div className="container">
            <div className="row">
              <div className="col-lg-8 col-sm-12 mx-auto text-center">
                <h2 className="idpl-heading-1 mb-3">
                  <span>We Measure Our Success By</span>
                  <span>The Value We Deliver</span>
                </h2>
                <p className="idpl-para-1 mb-4">
                  All that we do and pledge to deliver to our people, community, and shareholders lie at the heart of our company's ethos.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-9 col-sm-12 mx-auto">
                <div className="values-wrp">
                  <div className="values-left">
                    <div className="main-val">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/BigHairyAudaciusGoal.svg`}
                        alt="icon"
                      />
                      <div className="main-val-data">
                        <b>Big Hairy audacious Goal</b>
                        <span>
                          To set for ourselves clear, compelling and audacious
                          goals.
                        </span>
                      </div>
                    </div>
                    <div className="main-val">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/RespectHumility&Intergrity.svg`}
                        alt="icon"
                      />
                      <div className="main-val-data">
                        <b>Respect, Humility & Integrity</b>
                        <span>
                          To respect and honour each individual’s unique talents.
                        </span>
                      </div>
                    </div>
                    <div className="main-val">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/SolvingBigProblem.svg`}
                        alt="icon"
                      />
                      <div className="main-val-data">
                        <b>Solve Big Problems</b>
                        <span>
                          To identify and resolve problems which have a huge
                          impact on business.
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className="mid-arrows"></div>
                  <div className="values-right">
                    <div className="main-val">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/CustomerFirst.svg`}
                        alt="icon"
                      />
                      <div className="main-val-data">
                        <b>Customer First</b>
                        <span>
                          To anticipate, understand and meet needs of internal
                          or external customers.
                        </span>
                      </div>
                    </div>
                    <div className="main-val">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/BeFrugal.svg`}
                        alt="icon"
                      />
                      <div className="main-val-data">
                        <b>Be Frugal</b>
                        <span>
                          To focus on need-based resource utilization.
                        </span>
                      </div>
                    </div>
                    <div className="main-val">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/Speed&Agility.svg`}
                        alt="icon"
                      />
                      <div className="main-val-data">
                        <b>Speed & Agility</b>
                        <span>
                          To continue to deliver on responsibilities while
                          responding to the Speed and agility evolving
                          environment.
                        </span>
                      </div>
                    </div>
                    <div className="main-val">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/AccountabilityForResults.svg`}
                        alt="icon"
                      />
                      <div className="main-val-data">
                        <b>Accountability For Results</b>
                        <span>
                          To take ownership of decisions or actions for self and
                          team.
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- =============== Value We Deliver Section End =============== --> */}

        {/* <!-- =============== Life at IDPL Section Start =============== --> */}
        <section className="lifeAtIDPL">
          <div className="container">
            <div className="row">
              <div className="col-lg-7 col-sm-12 mx-auto text-center">
                <h2 className="idpl-heading-1 mb-3">
                  <span>Life At IDPL</span>
                </h2>
                <p className="idpl-para-1 mb-4">
                  We firmly believe that when we provide our employees with the
                  right tools, they become more resilient, resolute and
                  determined to succeed.
                </p>
              </div>
            </div>

            <div className="row">
              <div className="col-md-10 mx-auto mt-4 mb-5">
                <div className="row g-0">
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="lifeAtIDPL_keys">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/exposure.svg`}
                        alt="icon"
                      />
                      <h4>Exposure</h4>
                      <p>
                        As India’s most diversified media conglomerate! We offer{" "}
                        <b>infinite opportunities</b> across digital
                        broadcasting and infotainment publishing space.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="lifeAtIDPL_keys">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/experienceChallenge.svg`}
                        alt="icon"
                      />
                      <h4>Experience Challenge</h4>
                      <p>
                        Polish your <b>infinite potential</b> while staying
                        abreast with challenging work opportunities to help you
                        accelerate your learning curve.
                      </p>
                    </div>
                  </div>
                  <div className="col-lg-4 col-md-4 col-sm-12">
                    <div className="lifeAtIDPL_keys border-0">
                      <img
                        src={`${IMAGE_BASE_URL}/icons/empowerment.svg`}
                        alt="icon"
                      />
                      <h4>Empowerment</h4>
                      <p>
                        At IDPL, you get the freedom of{" "}
                        <b>infinite creativity</b> and a chance to innovate,
                        experiment and make your own decisions.
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-lg-12 col-sm-12">
                <img
                  src={`${IMAGE_BASE_URL}/lifeAtIDPL.svg`}
                  className="lifeAtIDPL_img"
                  alt="Life At IDPL"
                />
              </div>
              <div className="col-lg-12 col-sm-12 text-center mt-5">
                <Link to={WebRoutes.OPENPOSITION} className="idpl-btn">
                  <span>View Open Positions</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- =============== Life at IDPL Section End =============== --> */}

        {/* <!-- =============== Testimonial Section Start =============== --> */}
        <section className="testimonial">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12 mx-auto text-center">
                <div className="idpl-sub-head text-white">Testimonial</div>
                <div className="idpl-heading-1 text-white mb-2">
                  <span>Employee Experience Journey</span>
                </div>
                {/* <p className="idpl-para-1 text-white mb-4">
                In publishing and graphic design, Lorem ipsum is a placeholder
                text commonly used to demonstrate the visual form of a document
                or a typeface without relying.
              </p> */}
              </div>
              <div className="col-lg-8 mx-auto mt-4">
                <OwlCarousel
                  className="owl-carousel owl-theme testimonial-carousel"
                  {...testimonial}
                >
                  {testimonialData.data &&
                    testimonialData.data.map((item) => (
                      <div className="item">
                        <div className="testimonial-wrp">
                          <div className="tstmnl-img">
                            <img src={item.image} alt="testimonial" />
                          </div>
                          <div className="tstmnl-data">
                            <img
                              src={`${IMAGE_BASE_URL}/icons/quote.svg`}
                              alt="quote"
                            />
                            <div className="tstmnl-para">
                              {parseHtml(item.description.substring(0, 150))}{" "}
                              ...
                            </div>
                            <span className="tstmnl-name">
                              {item.name}, {item.designation}
                            </span>
                          </div>
                        </div>
                      </div>
                    ))}
                </OwlCarousel>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- =============== Testimonial Section End =============== --> */}

        {/* <!-- =============== Digital Ecosystem Section Start =============== --> */}
        <section className="digitalEcosystem">
          <div className="container">
            <div className="row">
              <div className="col-lg-12 col-sm-12 mx-auto text-center">
                <div className="idpl-sub-head text-1">
                  Our Digital Ecosystem
                </div>
                <h2 className="idpl-heading-1 mb-3">
                  <span>IDPL (Formerly Rapidcube Technologies) Is India’s One</span>
                  <span>Of The Largest Digital Media Network with</span>
                  <span>32 Digital Properties</span>
                </h2>
                <p className="idpl-para-1 mb-md-4 mt-4">
                  More than 15,000,000 people around the world interact with our
                  products every day. With decades of trust, these properties
                  have emerged as the most celebrated and preferred online news
                  and information destinations.
                </p>
              </div>

              <div className="col-md-12">
                <OwlCarousel
                  className="owl-carousel owl-theme digitalEcosystem-carousel"
                  {...digitalEcosystem}
                >
                  {productData.data &&
                    productData.data.map((item) => (
                      <div className="item">
                        <div
                          className={`channel-card ${item.theme && item.theme.color_class
                            }`}
                        >
                          <img
                            src={item.logo}
                            alt="Channel-Logo"
                            className="channel-logo"
                          />
                          <p>{parseHtml(item.description)}</p>
                          <div className="store-rating">
                            <a
                              className="web-store"
                              href={item.play_store}
                              target="_blank"
                            >
                              <img
                                src={`${IMAGE_BASE_URL}/icons/play-store.svg`}
                                alt="play-store"
                              />
                              <div>
                                <span>Play Store</span>
                                <b>{item.play_store_value}</b>
                              </div>
                            </a>
                            <a
                              className="web-store"
                              href={item.app_store}
                              target="_blank"
                            >
                              <img
                                src={`${IMAGE_BASE_URL}/icons/app-store.svg`}
                                alt="app-store"
                              />
                              <div>
                                <span>App Store</span>
                                <b>{item.app_store_value}</b>
                              </div>
                            </a>
                          </div>
                        </div>
                      </div>
                    ))}
                </OwlCarousel>
              </div>

              <div className="col-lg-12 col-sm-12 text-center my-3">
                <Link to={WebRoutes.PRODUCT} className="idpl-btn">
                  <span>View More</span>
                </Link>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- =============== Digital Ecosystem Section End =============== --> */}

        {/* <!-- =============== Newsletters Section Start =============== --> */}
        <section className="newsletters">
          <div className="container">
            <div className="row align-items-center">
              <div className="col-lg-6 col-sm-12">
                <h2 className="idpl-heading-1 mb-3">
                  <span>Subscribe To</span>
                  <span>Our Newsletter</span>
                </h2>
                <b className="nltr-para">
                  Sign up to be the first to receive updates on sales, marketing, product announcements, and consumer insights in your inbox.
                </b>
              </div>
              <div className="col-lg-4 offset-lg-1 col-sm-12">
                <Form
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  onFinish={onFinish}
                >
                  <div className="newsletters-form">
                    <input
                      type="email"
                      placeholder="Enter your email"
                      required
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <button type="submit">Subscribe</button>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </section>
        {/* <!-- =============== Newsletters Section End =============== --> */}
      </DocumentMeta>
    </>
  );
};

const mapStateToProps = (state) => {
  const { HomeReducer, ProductReducer, CommonReducer } = state;
  const { dashboardData, testimonialData } = HomeReducer;
  const { productData } = ProductReducer;

  return {
    dashboardData: HomeReducer.dashboardData,
    testimonialData: HomeReducer.testimonialData,
    productData: ProductReducer.productData,
    toastData: CommonReducer.toastData,
    toastType: CommonReducer.toastType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    userCount: () => dispatch(userCount()),
    testimonials: () => dispatch(testimonials()),
    product: () => dispatch(product()),
    emailSubscriptionApi: (data) => dispatch(emailSubscriptionApi(data)),
    resetToast: () => dispatch(resetToast()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
