import axios from "axios";
import { PRIVACY_POLICY, TERMS } from "../constant";
import { getCommonApiHeader } from "../../utils/util";


export const privacy = (data) => {
    return (dispatch, getState) => {
        dispatch(getPrivayDataRequest());
        axios
            .get(PRIVACY_POLICY, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getPrivacyDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const terms = (data) => {
    return (dispatch, getState) => {
        dispatch(getPrivayDataRequest());
        axios
            .get(TERMS, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getTermsDataTeamRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}


export const getPrivayDataRequest = data => {
    return {
        type: "Privacy_Data_REQUESTED",
    };
};

export const getPrivacyDataRespond = data => {
    return {
        type: "PRIVACY_DATA_RESPONSE",
        data: data,
    };
};
export const getTermsDataTeamRespond = data => {
    return {
        type: "Terms_Data_Team_RESPONSE",
        data: data,
    };
};
export const handleError = error => {
    return {
        type: "Privacy_ERROR",
        error,
    };
};
