import React from "react";
import { Modal } from "react-bootstrap";
import { Form, Input } from "antd";
import validator from "validator";
import {
  FacebookShareButton,
  LinkedinShareButton,
  TwitterShareButton,
  WhatsappShareButton,
  EmailShareButton,

  // Comment to sepaate, overwriting codesandbox behavior
  FacebookIcon,
  TwitterIcon,
  LinkedinIcon,
  WhatsappIcon,
  EmailIcon,
} from "react-share";
import { WebRoutes } from "../../../route";

function Share({ openShare, handleCancel, jobDetailsData }) {

  return (
    <>
      <Modal
        show={openShare}
        onHide={handleCancel}
        centered
        className="idpl-modal"
        backdrop={false}
      >
        <Modal.Header closeButton>
          <Modal.Title>Share Job</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="shareJobWrp">
            <FacebookShareButton url={`${window.location.origin.toString()}${WebRoutes.SHARE_JOB}/${jobDetailsData && jobDetailsData.data.id}`}>
              <FacebookIcon round />
            </FacebookShareButton>
            <WhatsappShareButton url={`${window.location.origin.toString()}${WebRoutes.SHARE_JOB}/${jobDetailsData && jobDetailsData.data.id}`}>
              <WhatsappIcon round />
            </WhatsappShareButton>
            <TwitterShareButton url={`${window.location.origin.toString()}${WebRoutes.SHARE_JOB}/${jobDetailsData && jobDetailsData.data.id}`}>
              <TwitterIcon round />
            </TwitterShareButton>
            <LinkedinShareButton url={`${window.location.origin.toString()}${WebRoutes.SHARE_JOB}/${jobDetailsData && jobDetailsData.data.id}`}>
              <LinkedinIcon round />
            </LinkedinShareButton>
            <EmailShareButton url={`${window.location.origin.toString()}${WebRoutes.SHARE_JOB}/${jobDetailsData && jobDetailsData.data.id}`}>
              <EmailIcon round />
            </EmailShareButton>
          </div>
        </Modal.Body>
      </Modal>
    </>
  );
}

export default Share;
