const initState = {
    contactData: [],
    error: null
};
const ContactReducer = (state = initState, action) => {
    const data = action?.data;
    switch (action?.type) {
        case "Contact_Data_REQUESTED":
            return {
                ...state,
            };
        case "Contact_DATA_RESPONSE":
            return {
                ...state,
                contactData: data,
            };
        case "Contact_ERROR":
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};
export default ContactReducer;
