import axios from "axios";
import { getCommonApiHeader } from "../../utils/util";
import { User_COUNT, User_TESTIMONIAL, EMAIL_SUBSCRIPTION } from "../constant";

export const userCount = (data) => {
    return (dispatch, getState) => {
        dispatch(getHomeDataRequest());
        axios
            .get(User_COUNT, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getHomeDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const testimonials = (data) => {
    return (dispatch, getState) => {
        dispatch(getHomeDataRequest());
        axios
            .get(User_TESTIMONIAL, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getHomeDataTestimonialRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const emailSubscriptionApi = (data) => {
    return (dispatch, getState) => {
        dispatch(getHomeDataRequest());
        axios
            .post(EMAIL_SUBSCRIPTION, data, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(handleSuccess({ message: response.data.message }));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}


export const getHomeDataRequest = data => {
    return {
        type: "HOME_DATA_REQUESTED",
    };
};

export const getHomeDataRespond = data => {
    return {
        type: "HOME_DATA_RESPONSE",
        data: data,
    };
};
export const getHomeDataTestimonialRespond = data => {
    return {
        type: "HOME_DATA_Testimonial_RESPONSE",
        data: data,
    };
};
export const handleError = error => {
    return {
        type: "HOME_ERROR",
        error,
    };
};

export const handleSuccess = (data) => {
    return {
        type: "SHOW_TOAST",
        data,
        toastType: "success",
    };
};
