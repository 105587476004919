import React from "react";
import { Link } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../redux/constant";
import { WebRoutes } from "../../route";


function Footer() {
  return (
    <footer>
      <div className="container">
        <div className="row">
          <div className="col-md-6 col-sm-12">
            <Link to="/">
              <img
                src={`${IMAGE_BASE_URL}/logo.svg`}
                alt="logo"
                className="logo"
              />
            </Link>
            <ul className="social">
              <li>
                <a href="https://www.linkedin.com/company/indiadotcomdigital/">
                  <img
                    src={`${IMAGE_BASE_URL}/icons/social-linkedIn.svg`}
                    alt="linkedIn"
                  />
                </a>
              </li>
              {/* <li>
                <img
                  src={`${IMAGE_BASE_URL}/icons/social-facebook.svg`}
                  alt="facebook"
                />
              </li> */}
            </ul>
          </div>
          <div className="col-md-6 col-sm-12">
            <div className="row">
              <div className="col-md-4 footer-links">
                <div className="h4">Quick Links</div>
                <ul>
                  <li>
                    <Link to={WebRoutes.PRODUCT}>Products</Link>
                  </li>
                  <li>
                    <Link to={WebRoutes.SERVICES}>Services</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 footer-links">
                <div className="h4">Company</div>
                <ul>
                  <li>
                    <Link to={WebRoutes.ABOUTUS}>About Us</Link>
                  </li>
                  <li>
                    <Link to={WebRoutes.CAREER}>Careers</Link>
                  </li>
                </ul>
              </div>
              <div className="col-md-4 footer-links">
                <div className="h4">Legal</div>
                <ul>
                  <li>
                    <Link to={WebRoutes.PRIVACY_POLICY}>Privacy & Policy</Link>
                  </li>
                  <li>
                    <Link to={WebRoutes.TERMS_CONDITIONS}>Terms & Conditions</Link>
                  </li>
                  <li>
                    <Link to={WebRoutes.CONTACTUS}>Contact Us</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="col-md-12">
            <div className="footer-bottom">
              Copyright © 2022 Indiadotcom Digital.PVT.LTD. All rights reserved.
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}

export default Footer;
