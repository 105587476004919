import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { connect } from "react-redux";
import { aboutVideo, teams, gallery } from "../../redux/action/about";
import { Link } from "react-router-dom";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { IMAGE_BASE_URL } from "../../redux/constant";

const AboutUs = ({
  aboutVideo,
  videoData,
  teams,
  teamData,
  gallery,
  galleryData,
}) => {
  const [playVideo, setPlayVideo] = useState(false);
  const companyGallery = {
    loop: false,
    margin: 20,
    nav: true,
    dots: false,
    center: true,
    autoplay: true,
    stagePadding: 350,
    navText: [
      '<svg width="24" height="25" viewBox="0 0 24 25" fill="#FFF" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M13.3625 17.1467C13.1005 17.1467 12.8395 17.0447 12.6435 16.8417L8.78053 12.8417C8.40253 12.4487 8.40753 11.8257 8.79353 11.4397L12.7935 7.43973C13.1835 7.04873 13.8165 7.04873 14.2075 7.43973C14.5975 7.83073 14.5975 8.46273 14.2075 8.85373L10.9025 12.1587L14.0815 15.4517C14.4655 15.8497 14.4545 16.4827 14.0575 16.8657C13.8625 17.0537 13.6125 17.1467 13.3625 17.1467Z" /></svg>',
      '<svg width="24" height="25" viewBox="0 0 24 25" fill="#FFF" xmlns="http://www.w3.org/2000/svg"><path fill-rule="evenodd" clip-rule="evenodd" d="M10.4998 17.1458C10.2438 17.1458 9.98776 17.0478 9.79276 16.8528C9.40176 16.4618 9.40176 15.8298 9.79276 15.4388L13.0978 12.1338L9.91776 8.84076C9.53476 8.44276 9.54576 7.80976 9.94276 7.42676C10.3408 7.04376 10.9738 7.05476 11.3568 7.45076L15.2188 11.4508C15.5978 11.8438 15.5928 12.4668 15.2068 12.8528L11.2068 16.8528C11.0118 17.0478 10.7558 17.1458 10.4998 17.1458Z"/></svg>',
    ],
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 1,
      },
      1000: {
        items: 1,
      },
    },
  };
  useEffect(() => {
    document.querySelector("#video_id").play();
    aboutVideo();
    teams();
    gallery();
  }, []);

  const handlePlay = () => {
    playVideo ? setPlayVideo(false) : setPlayVideo(true);
  };

  const meta = {
    title: "About Us @IDPL - IndiaDotCom Digital Pvt Ltd",
    description:
      "About IDPL - IndiaDotcom Digital Pvt Ltd (formerly Zee Digital) is India’s one of the largest Digital Media Network. Know more about IDPL digital sevices and prouducts. We reached to millions users via content and diversified products for audiences, and languages.",
    meta: {
      name: {
        keywords:
          "digital news, media publishing, Zee Digital, IDPL services, IDPL products, about IDPL, IDPL, IndiaDotCom Digital Pvt Ltd",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      {/* <!-- =============== Hero Section Start =============== --> */}
      <section className="hero-section-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 mx-auto">
              <div className="hero-title">
                <span>We're More Than A </span>
                <span>Digital Media Publishing Group</span>
              </div>
            </div>
            <div className="col-md-8 mx-auto">
              <div className="hero-sub-title">
                We are the next-generation global media company to cut across
                borders and help the world reimagine news and information
                broadcasting for the new age digital.
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- =============== Hero Section End =============== --> */}

      {/* <!-- =============== About Company Start =============== --> */}
      <section className="container pt-5">
        <div className="row">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="idpl-heading-1 mb-3 mb-md-0">Our Company</div>
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12">
            <p className="idpl-para-2 mb-0">
              The Legacy of IndiaDotcom Digital Private Limited has touched
              millions—through useful content which is available across
              platforms.
            </p>
            <p className="idpl-para-2 mb-0">
              Our diversified products create content for a variety of
              audiences, across every genre, format, and language while our
              networks and brands forge deep connections with the most diverse
              audiences across the world.
            </p>
            <p className="idpl-para-2 mb-0 pt-md-4">
              In terms of news gathering, the company has one of the largest
              networks of news bureaus and correspondents with a PAN India
              presence. The company is equipped with advanced technology and
              differentiated strategy which is scaling rapidly across content
              creation, packaging and broadcasting. We are all set to explore
              the endless possibilities to transmit infinite information
              available digitally to all.
            </p>
          </div>
        </div>
      </section>
      {/* <!-- =============== About Company End =============== --> */}

      {/* <!-- =============== Company Video Start =============== --> */}
      <section className="">
        <video
          className="w-100"
          loop="false"
          id="video_id"
          play={playVideo}
          // loop={true}
          autoplay=""
          muted
          onClick={() => handlePlay()}
        >
          {videoData.data ? (
            <source
              src="https://idplbucket2.s3.ap-south-1.amazonaws.com/images/IDPL_LOGO_ANIMATION+(3)+(1)+(1)+(2)+(1).mp4"
              type="video/mp4"
            />
          ) : (
            <source
              src="https://idplbucket2.s3.ap-south-1.amazonaws.com/images/IDPL_LOGO_ANIMATION+(3)+(1)+(1)+(2)+(1).mp4"
              type="video/mp4"
            />
          )}
        </video>
      </section>
      {/* <!-- =============== Company Video End =============== --> */}

      {/* <!-- =============== Strong Together Start =============== --> */}
      <section className="container pt-5">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="idpl-heading-1">Together we are strong</div>
          </div>
          <div className="col-lg-3 col-md-3 col-md-12">
            <div className="together-tab">
              <b>32</b>
              <span>Iconic Digital Properties</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-md-12">
            <div className="together-tab">
              <b>11</b>
              <span>Different Languages</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-md-12">
            <div className="together-tab">
              <b>1399+</b>
              <span>Million Pageviews</span>
            </div>
          </div>
          <div className="col-lg-3 col-md-3 col-md-12">
            <div className="together-tab">
              <b>345+</b>
              <span>Million Monthly Unique Visitors</span>
            </div>
          </div>
          <div className="col-md-12">
            <img
              src={`${IMAGE_BASE_URL}/icons/bottom-arrow.svg`}
              alt="arrow-slide"
              className="d-none d-md-block"
            />
          </div>
        </div>
      </section>
      {/* <!-- =============== Strong Together End =============== --> */}

      {/* <!-- ===============  Our Vision & Mission Start =============== --> */}
      <section className="container pt-5">
        <div className="row align-items-center py-4">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="idpl-heading-1 mb-3 mb-md-0">Our Vision</div>
            <p className="idpl-para-2">
              Our purpose is to create an informed and empowered world through
              factual and real-time news and information updates 24/7. We are
              dedicated to entertaining, engaging and enriching the lives of the
              Indian diaspora and audiences across the globe through sustained
              media innovation & thought leadership.
            </p>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 offset-lg-1 offset-md-1">
            <img
              src={`${IMAGE_BASE_URL}/icons/our-vision.svg`}
              alt="our-vision"
              className="img-fluid"
            />
          </div>
        </div>

        <div className="row align-items-center py-4">
          <div className="col-lg-6 col-md-6 col-sm-12">
            <div className="idpl-heading-1 mb-3 mb-md-0">Our Mission</div>
            <p className="idpl-para-2">
              We aspire to be recognized as the world's leading digital media
              company with our digital first projects and technology
              advancements. As a star organisation, we will be driven by
              infinite possibilities of innovation and creativity and would
              focus on growth while delivering exceptional value to our
              customers, our viewers and all our stakeholders.
            </p>
          </div>
          <div className="col-lg-5 col-md-5 col-sm-12 offset-lg-1 offset-md-1">
            <img
              src={`${IMAGE_BASE_URL}/icons/our-mission.svg`}
              alt="our-mission"
              className="img-fluid"
            />
          </div>
        </div>
      </section>
      {/* <!-- ===============  Our Vision & Mission End =============== --> */}

      {/* <!-- ===============  Leadership Team Start =============== --> */}
      {/* <section className="container pt-5">
        <div className="row">
          <div className="col-lg-12 col-md-12 col-sm-12">
            <div className="idpl-heading-1">Meet Our Leadership Team</div>
          </div>
          <div className="col-lg-12 col-md-12 col-sm-12 py-4">
            <div className="leaders-wrp">
              {teamData.data &&
                teamData.data.map((item) => (
                  <div className="leaders">
                    <img src={item.profile_image} alt="Leaders" />
                    <b>{item.name}</b>
                    <span>{item.positions}</span>
                    <ul>
                      <li>
                        <a href={item.linked_link} target="_blank">
                          <img src={`${IMAGE_BASE_URL}/icons/social-linkedIn.svg`} alt="LinkedIn" />
                        </a>
                      </li>
                     
                    </ul>
                  </div>
                ))}
            </div>
          </div>
        </div>
      </section> */}
      {/* <!-- ===============  Leadership Team End =============== --> */}

      {/* <!-- =============== Company Gallery Slider Start =============== --> */}
      <section className="gallerySlider">
        <OwlCarousel
          className="owl-carousel owl-theme companyGallery"
          {...companyGallery}
        >
          {galleryData.data &&
            galleryData.data.map((item) => (
              <div className="item">
                <img src={item.image} alt="gallery" />
              </div>
            ))}
        </OwlCarousel>
      </section>
      {/* <!-- =============== Company Gallery Slider End =============== --> */}
    </DocumentMeta>
  );
};

const mapStateToProps = (state) => {
  const { AboutReducer } = state;
  const { videoData, teamData, galleryData } = AboutReducer;
  return {
    videoData: AboutReducer.videoData,
    teamData: AboutReducer.teamData,
    galleryData: AboutReducer.galleryData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    aboutVideo: () => dispatch(aboutVideo()),
    teams: () => dispatch(teams()),
    gallery: () => dispatch(gallery()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(AboutUs);
