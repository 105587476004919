import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import { jobs } from "../../redux/action/job";
import { IMAGE_BASE_URL } from "../../redux/constant";
import { WebRoutes } from "../../route";
import { parseHtml } from "../../utils/util";

const Careers = ({ jobs, jobData }) => {
  useEffect(() => {
    jobs();
  }, []);

  const handleJobDescription = (id) => {
    localStorage.setItem('jobDescription', id);
  }

  const meta = {
    title:
      "Digital Publishing, Tech Jobs, Media Hirings, Careers @IDPL - IndiaDotCom Digital Pvt Ltd",
    description:
      "Hiring Jobs and Careers @IDPL - IndiaDotcom Digital Pvt Ltd (formerly Zee Digital). Be the part of the India’s No.1 Tech Led Digital Media Network. We are hiring for Tech Jobs, Product Manager, Digit Marketing, SEO Managers, Content Writers, Media Jobs and more. Check opportunities with job opening, positions available with IDPL digital sevices and prouducts.",
    meta: {
      name: {
        keywords:
          "digital jobs, media career, media hiring, Tech Jobs, Product Manager, Digit Marketing, SEO Managers, Content Writers, Media Jobs, content writing jobs, Zee Digital, latest job opening, jobs IDPL, positions IDPL, IDPL, IndiaDotCom Digital Pvt Ltd",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      {/* <!-- =============== Hero Section Start =============== --> */}
      <section className="careers-hero">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 mx-auto">
              <h1 className="idpl-heading-2">
                <span>Unlock your true potential &</span>
                <span>experience the power</span>
                <span>of infinite opportunities</span>
              </h1>
              <div className="idpl-para-2 my-3 text-center">
                <b>
                  Be The Part of India’s No.1 Tech Led Digital Media Network
                </b>
              </div>
              <a href="#OpenPositionsWrp" className="workWithUs idpl-btn">
                <span>Work With Us</span>
              </a>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- =============== Hero Section End =============== --> */}

      {/* <!-- =============== Employee Benefits & Perks Start =============== --> */}
      <section className="container py-5">
        <div className="row">
          <div className="col-lg-7 col-sm-12 mx-auto text-center">
            <div className="idpl-heading-1">
              <span>Employee Benefits & Perks</span>
            </div>
            <div className="idpl-para-2 mt-3">
              IDPL is proud to provide ample benefits and programs that support
              our diverse employees in their personal lives.
            </div>
          </div>

          <div className="col-lg-10 col-sm-12 mx-auto">
            <div className="row g-4 my-4">
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="benefitsPerks">
                  <img
                    src={`${IMAGE_BASE_URL}/icons/exposure.svg`}
                    alt="icon"
                  />
                  <b>Flexible Working</b>
                  <p>
                    Hybrid work model that flexes with employee’s weekly
                    schedule and other programs that provide schedule
                    assistance.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="benefitsPerks">
                  <img
                    src={`${IMAGE_BASE_URL}/icons/VacationPolicy.svg`}
                    alt="icon"
                  />
                  <b>Vacation Policy</b>
                  <p>
                    Generous paid leave for employees at all stages of life:
                    maternity & paternity leave, caregiver leave, and sick
                    leave.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="benefitsPerks">
                  <img
                    src={`${IMAGE_BASE_URL}/icons/empowerment.svg`}
                    alt="icon"
                  />
                  <b>Wellness and Fitness Centres</b>
                  <p>
                    Monthly intellectual wellbeing workshops. Access to fitness
                    programs like daily yoga sessions; in-house gym facilities.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="benefitsPerks">
                  <img
                    src={`${IMAGE_BASE_URL}/icons/ComprehensiveHealth.svg`}
                    alt="icon"
                  />
                  <b>Comprehensive Health Plans</b>
                  <p>
                    Premium coverage to employees through health, dental, and
                    vision plans. Family Health benefits are available, as are
                    parental medical insurance.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="benefitsPerks">
                  <img
                    src={`${IMAGE_BASE_URL}/icons/experienceChallenge.svg`}
                    alt="icon"
                  />
                  <b>Educational Support</b>
                  <p>
                    Access to online courses for career upscaling. Knowledge
                    sharing “Interact with Experts” workshops with industry
                    thought leaders every month. In-house library facility.
                  </p>
                </div>
              </div>
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className="benefitsPerks">
                  <img
                    src={`${IMAGE_BASE_URL}/icons/ChildrenDayCare.svg`}
                    alt="icon"
                  />
                  <b>Children Day Care Centre</b>
                  <p>
                    To ease the stress and worry of working parents. We have our on-site childcare facility with Kidzee where kids can learn and grow. Service is available in selected offices.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- =============== Employee Benefits & Perks End =============== --> */}

      {/* <!-- =============== Grow with Us Start =============== --> */}
      <section className="container">
        <div className="row">
          <div className="col-lg-12 col-sm-12 text-center">
            <div className="idpl-heading-1">
              <span> IDPL is growing. Grow with us.</span>
            </div>
            <div className="GrowWithUs-para">
              <span>
                We believe that the transition from traditional TV News to
                Digital News is one of the
              </span>
              <span>
                most important shifts of our time. Our mission is to make that
                happen
              </span>
              <span>faster and better.</span>
            </div>
          </div>
        </div>
      </section>
      <section className=" bg-2">
        <video
          className="w-100"
          data-aos="zoom-in"
          data-aos-delay="100"
          autoplay="true"
          loop="true"
        >
          <source
            src="https://idplbucket2.s3.ap-south-1.amazonaws.com/images/Career+Page+Video.mp4"
            type="video/mp4"
          />
        </video>
      </section>
      {/* <!-- =============== Grow with Us End =============== --> */}

      {/* <!-- =============== Open Positions Start =============== --> */}
      <section id="OpenPositionsWrp">
        <div className="container">
          <div className="row">
            <div className="col-lg-4 col-sm-12">
              <div className="OpenPositions-heading">
                <span>Open</span>
                <span>Positions</span>
              </div>
            </div>
            <div className="col-lg-6 offset-lg-2 col-md-6 offset-md-2 col-sm-12">
              <div className="openPosCont">
                {jobData.data &&
                  jobData.data.slice(0, 5).map((item) => (
                    <Link to={WebRoutes.JOBDESCRIPTION} className="openPos" onClick={(e) => handleJobDescription(item.id)}>
                      <h4>{item.title}</h4>
                      <ul>
                        <li>
                          <span>Location:</span>
                          <b>{item.location}</b>
                        </li>
                        <li>
                          <span>Role:</span>
                          <b>{item.role}</b>
                        </li>
                      </ul>
                      <button>
                        <img
                          src={`${IMAGE_BASE_URL}/icons/arrow-right.svg`}
                          alt="icon"
                        />
                      </button>
                    </Link>
                  ))}
              </div>
              {/* <Link to="/openPositions" className="idpl-btn">
                <span>View More Positions</span>
                <img src={`${IMAGE_BASE_URL}/icons/arrow-right.svg`} alt="icon" />
              </Link> */}
            </div>
          </div>
        </div>
      </section>
      {/* <!-- =============== Open Positions End =============== --> */}
    </DocumentMeta>
  );
};
const mapStateToProps = (state) => {
  const { JobReducer } = state;
  return {
    jobData: JobReducer.jobData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    jobs: () => dispatch(jobs()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Careers);
