export const BASE_URL = 'https://idpl.ai/idpl/public/api';

export const IMAGE_BASE_URL = 'https://idplbucket2.s3.ap-south-1.amazonaws.com/idpl/img';


// user count api
export const User_COUNT = `${BASE_URL}/user-count-list`;

// testimonial api
export const User_TESTIMONIAL = `${BASE_URL}/user-testimonials-list`;

// About video api
export const ABOUT_VIDEO = `${BASE_URL}/about-video-list`;

// Team api
export const TEAM = `${BASE_URL}/team-list`;

// Privacy Policy api
export const PRIVACY_POLICY = `${BASE_URL}/privacy-list`;

// Terms api
export const TERMS = `${BASE_URL}/term-list`;

// Contact api
export const CONTACT = `${BASE_URL}/contact-addresses-list`;

// Product api
export const PRODUCTLIST = `${BASE_URL}/product-list`;

// Post form api
export const POSTFORM = `${BASE_URL}/post-form`;

// Jobs api
export const JOBSAPI = `${BASE_URL}/jobs-list`;


// Job detail api
export const JOB_DETAIL = `${BASE_URL}/jobs-detail/`;

// Gallery api
export const GALLERY = `${BASE_URL}/gallary-list`;

// Corporate post fom api
export const CORPORATE_PRESENTATION = `${BASE_URL}/corporate-fom`;

// Job post fom api
export const SHARED_RESUME = `${BASE_URL}/share-resume`;

// Job Apply fom api
export const JOB_APPLY = `${BASE_URL}/job-apply`;

// Search job api
export const SEARCH_API = `${BASE_URL}/search-apply`;


// email subscription api
export const EMAIL_SUBSCRIPTION = `${BASE_URL}/email-Subscription`;

