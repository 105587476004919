import axios from "axios";
import { json } from "react-router-dom";
import { CONTACT, POSTFORM, CORPORATE_PRESENTATION } from "../constant";
import { getCommonApiHeader } from "../../utils/util";


export const constact = (data) => {
    return (dispatch, getState) => {
        dispatch(getContactDataRequest());
        axios
            .get(CONTACT, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getContactDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const postForm = (data) => {
    return (dispatch, getState) => {
        dispatch(getContactPostDataRequest());
        axios
            .post(POSTFORM, data, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                if (response) {
                    dispatch(handleSuccess({ message: response.data.message }));
                }
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const corporateForm = (data) => {
    return (dispatch, getState) => {
        dispatch(getContactPostDataRequest());
        axios
            .post(CORPORATE_PRESENTATION, data, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                if (response) {
                    const file = response.data.data;
                    console.log(file.file_name);
                    if (file) {

                        // Creating new object of PDF file
                        // const fileURL = window.URL.createObjectURL(new Blob([response.data.file_name]));
                        // Setting various property values
                        let alink = document.createElement('a');
                        alink.href = file.file_name;
                        alink.target = '_blank';
                        alink.click();
                    }

                    dispatch(handleSuccess({ message: response.data.message }));
                }
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}



export const getContactDataRequest = data => {
    return {
        type: "Contact_Data_REQUESTED",
    };
};

export const getContactPostDataRequest = data => {
    return {
        type: "Contact_POST_Data_REQUESTED",
    };
};

export const getContactDataRespond = data => {
    return {
        type: "Contact_DATA_RESPONSE",
        data: data,
    };
};

export const getContactPostDataRespond = data => {
    return {
        type: "Contact_POST_DATA_RESPONSE",
        data: data,
    };
};

export const handleSuccess = (data) => {
    return {
        type: "SHOW_TOAST",
        data,
        toastType: "success",
    };
};
export const handleError = error => {
    return {
        type: "Contact_ERROR",
        error,
    };
};
