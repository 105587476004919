import axios from "axios";
import { ABOUT_VIDEO, TEAM, GALLERY } from "../constant";
import { getCommonApiHeader } from "../../utils/util";



export const aboutVideo = (data) => {
    return (dispatch, getState) => {
        dispatch(getAboutDataRequest());
        axios
            .get(ABOUT_VIDEO, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getAboutDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const teams = (data) => {
    return (dispatch, getState) => {
        dispatch(getAboutDataRequest());
        axios
            .get(TEAM, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getAboutDataTeamRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const gallery = (data) => {
    return (dispatch, getState) => {
        dispatch(getGalleryDataRequest());
        axios
            .get(GALLERY, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getGalleryDataTeamRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}


export const getAboutDataRequest = data => {
    return {
        type: "About_Data_REQUESTED",
    };
};

export const getGalleryDataRequest = data => {
    return {
        type: "Gallery_Data_REQUESTED",
    };
};

export const getGalleryDataTeamRespond = data => {
    return {
        type: "Gallery_DATA_RESPONSE",
        data: data,
    };
};

export const getAboutDataRespond = data => {
    return {
        type: "About_DATA_RESPONSE",
        data: data,
    };
};
export const getAboutDataTeamRespond = data => {
    return {
        type: "About_Data_Team_RESPONSE",
        data: data,
    };
};
export const handleError = error => {
    return {
        type: "ABOUT_ERROR",
        error,
    };
};
