import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { connect } from "react-redux";
import { Form, Input, message } from "antd";
import { constact, postForm } from "../../redux/action/contact";
import { IMAGE_BASE_URL } from "../../redux/constant";
import { parseHtml } from "../../utils/util";
import { resetToast } from "../../redux/action/common";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const ContactUs = ({
  constact,
  contactData,
  postForm,
  toastType,
  toastData,
  resetToast,
}) => {
  const [name, setName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [city, setCity] = useState("");
  useEffect(() => {
    constact();
  }, []);

  useEffect(() => {
    if (toastType) {
      showToast();
      resetToast();
    }
  }, [toastType]);

  const showToast = () => {
    switch (toastType) {
      case "success":
        toast.success(toastData.message);
        break;
      case "error":
        toast.error(toastData.message);
        break;
      case "warning":
        toast.warning(toastData.message);
        break;
      default:
        break;
    }
  };
  const onFinish = () => {
    const data = {
      name: name,
      mobile: mobile,
      email: email,
      location: location,
      city: city,
    };
    postForm(data);
  };

  const meta = {
    title:
      "Email, Phone, Office Address - Contact Us @IDPL - IndiaDotCom Digital Pvt Ltd",
    description:
      "Contact Us @IDPL - IndiaDotcom Digital Pvt Ltd (formerly Zee Digital) is one of the India’s No.1 Tech Led Digital Media Network. Please contact us at phone, email or reach at office address in Noida and Mumbai",
    meta: {
      name: {
        keywords:
          "Office address IDPL, Contact IDPL, Contact Us IDPL, IDPL, IndiaDotCom Digital Pvt Ltd",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      <ToastContainer />
      {/* <!-- =============== Hero Section Start =============== --> */}
      <section className="hero-section-2">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 mx-auto">
              <h1 className="hero-title">Connect With Us</h1>
              <div className="hero-sub-title">
              For all sales, partnership, and advertisement-related inquiries, please email us using the form below.
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- =============== Hero Section End =============== --> */}

      {/* <!-- =============== Contact Form Start =============== --> */}
      <div className="container ContactForm">
        <div className="row">
          <div className="col-lg-11 col-sm-12 mx-auto">
            <div className="row align-items-center">
              <div className="col-lg-5 col-md-5">
                <div className="contact-txt">
                  <img
                    src={`${IMAGE_BASE_URL}/icons/contactUs.svg`}
                    alt="Contact-Us"
                  />
                  <div className="idpl-heading-1">
                    <span>Get in touch</span>
                    <span>with us</span>
                  </div>
                  <div className="contact-email">
                    <b>You can mail us at:</b>
                    <span>Info@india.com</span>
                    <span>Career@india.com</span>
                  </div>
                </div>
              </div>
              <div className="col-lg-5 col-md-5 offset-lg-2 offset-md-2">
                <Form
                  labelCol={{
                    span: 8,
                  }}
                  wrapperCol={{
                    span: 16,
                  }}
                  onFinish={onFinish}
                >
                  <div className="form-wrp">
                    <div className="idpl-form">
                      <Form.Item
                        label="Name"
                        name="name"
                        className="form-label"
                        rules={[
                          {
                            required: true,
                            message: "Please input your name!",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="name"
                          value={name}
                          className="form-control"
                          placeholder="Eg: John Doe"
                          onChange={(e) => setName(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="idpl-form">
                      <Form.Item
                        label="Mobile Number"
                        name="mobile"
                        className="form-label"
                        rules={[
                          {
                            required: true,
                            message: "Please put  your mobile number!",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="mobile"
                          className="form-control"
                          placeholder="Eg: 991234XXXX"
                          value={mobile}
                          onChange={(e) => setMobile(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="idpl-form">
                      <Form.Item
                        label="Email"
                        name="email"
                        className="form-label"
                        rules={[
                          {
                            required: true,
                            message: "Please put  your email Address!",
                          },
                        ]}
                      >
                        <Input
                          type="email"
                          name="email"
                          className="form-control"
                          placeholder="Eg: name@example.com"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="idpl-form">
                      <Form.Item
                        label="Location"
                        name="location"
                        className="form-label"
                        rules={[
                          {
                            required: true,
                            message: "Please put  your prefer location!",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="location"
                          className="form-control"
                          placeholder="Eg: Noida"
                          value={location}
                          onChange={(e) => setLocation(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="idpl-form">
                      <Form.Item
                        label="Organisation"
                        name="city"
                        className="form-label"
                        rules={[
                          {
                            required: true,
                            message: "Please put  your prefer organisation!",
                          },
                        ]}
                      >
                        <Input
                          type="text"
                          name="city"
                          className="form-control"
                          placeholder="Eg: Noida"
                          value={city}
                          onChange={(e) => setCity(e.target.value)}
                        />
                      </Form.Item>
                    </div>
                    <div className="idpl-form">
                      <button className="idpl-form-btn">Submit</button>
                    </div>
                  </div>
                </Form>
              </div>
            </div>
          </div>
        </div>
      </div>
      {/* <!-- =============== Contact Form End =============== --> */}

      {/* <!-- =============== Contact Details Start =============== --> */}
      <section className="container mb-md-5 mb-0 pb-5">
        <div className="row g-4">
          <div className="col-md-12 mb-4 pb-3">
            <div className="idpl-heading-1">Contact Address</div>
          </div>
          {contactData.data &&
            contactData.data.map((item) => (
              <div className="col-md-6 mb-4 mb-md-0">
                <div className="contact-dtls">
                  <div className="office-head">{item.office}:</div>
                  <ul>
                    <li>
                      <img
                        src={`${IMAGE_BASE_URL}/icons/location-pin.svg`}
                        alt="location-pin"
                      />
                      <span>
                        {item.address1},{item.address2},{item.address3}
                      </span>
                    </li>
                    <li>
                      <img
                        src={`${IMAGE_BASE_URL}/icons/call.svg`}
                        alt="call"
                      />
                      <span>{item.phone_no}</span>
                    </li>
                    <li>
                      <img
                        src={`${IMAGE_BASE_URL}/icons/mail.svg`}
                        alt="email"
                      />
                      <span>{item.email}</span>
                      <i>({item.tag})</i>
                    </li>
                  </ul>
                </div>
              </div>
            ))}
        </div>
      </section>
      {/* <!-- =============== Contact Details End =============== --> */}
    </DocumentMeta>
  );
};
const mapStateToProps = (state) => {
  const { ContactReducer, CommonReducer } = state;
  const { contactData } = ContactReducer;
  return {
    contactData: ContactReducer.contactData,
    toastData: CommonReducer.toastData,
    toastType: CommonReducer.toastType,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    constact: () => dispatch(constact()),
    postForm: (data) => dispatch(postForm(data)),
    resetToast: () => dispatch(resetToast()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ContactUs);
