export const WebRoutes = {
  HOME: "/",
  PRODUCT: "/digital-products",
  SERVICES: "/digital-services",
  ABOUTUS: "/about-us",
  CAREER: "/careers",
  OPENPOSITION: "/openPositions",
  JOBDESCRIPTION: "/jobDescription",
  CONTACTUS: "/contact-us",
  PRIVACY_POLICY: "/privacy-policy",
  TERMS_CONDITIONS: "/terms-conditions",
  SHARE_JOB: "/job-share",
};
