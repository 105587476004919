const initState = {
    videoData: [],
    teamData: [],
    galleryData: [],
    error: null
};
const AboutReducer = (state = initState, action) => {
    const data = action?.data;
    switch (action?.type) {
        case "About_Data_REQUESTED":
            return {
                ...state,
            };
        case "getGalleryDataRequest":
            return {
                ...state,
            };
        case "About_DATA_RESPONSE":
            return {
                ...state,
                videoData: data,
            };
        case "About_Data_Team_RESPONSE":
            return {
                ...state,
                teamData: data,
            };
        case "Gallery_DATA_RESPONSE":
            return {
                ...state,
                galleryData: data,
            };
        case "ABOUT_ERROR":
            return {
                ...state,
                error: action.error,
            };
        default:
            return state;
    }
};
export default AboutReducer;
