import React, { useEffect } from "react";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import ScrollToTop from "./user/components/ScrollToTop";
import Header from "./user/components/Header";
import Footer from "./user/components/Footer";
import Home from "./user/components/Home";
import Products from "./user/components/Products";
import Services from "./user/components/Services";
import AboutUs from "./user/components/AboutUs";
import ContactUs from "./user/components/ContactUs";
import PrivacyPolicy from "./user/components/PrivacyPolicy";
import TermConditions from "./user/components/TermConditions";
import { Provider } from "react-redux";
import { store } from "./redux/store";
import Careers from "./user/components/Careers";
import OpenPositions from "./user/components/OpenPositions";
import JobDescription from "./user/components/JobDescription";
import { WebRoutes } from "./route";
import JobShare from "./user/components/JobShare";

function App() {



  useEffect(() => {
    
    // const handleContextmenu = e => {
    //   e.preventDefault()
    // }
    // document.addEventListener('contextmenu', handleContextmenu)
    // return function cleanup() {
    //   document.removeEventListener('contextmenu', handleContextmenu)
    // }


  }, []);


  useEffect(() => {
    // ========== Easy selector helper function ==========
    const select = (el, all = false) => {
      el = el.trim();
      if (all) {
        return [...document.querySelectorAll(el)];
      } else {
        return document.querySelector(el);
      }
    };



    // ========== Easy event listener function ==========
    const on = (type, el, listener, all = false) => {
      let selectEl = select(el, all);
      if (selectEl) {
        if (all) {
          selectEl.forEach((e) => e.addEventListener(type, listener));
        } else {
          selectEl.addEventListener(type, listener);
        }
      }
    };

    // ========== Easy on scroll event listener ==========
    const onscroll = (el, listener) => {
      el.addEventListener("scroll", listener);
    };

    // ========== Scrolls to an element with header offset ==========
    const scrollto = (el) => {
      let header = select("#header");
      let offset = header.offsetHeight;
      if (!header.classList.contains("header-scrolled")) {
        offset -= 16;
      }
      let elementPos = select(el).offsetTop;
      window.scrollTo({
        top: elementPos - offset,
        behavior: "smooth",
      });
    };

    // ========== Toggle .header-scrolled class to #header when page is scrolled ==========
    let selectHeader = select("#header");
    if (selectHeader) {
      const headerScrolled = () => {
        if (window.scrollY > 100) {
          selectHeader.classList.add("header-scrolled");
        } else {
          selectHeader.classList.remove("header-scrolled");
        }
      };
      window.addEventListener("load", headerScrolled);
      onscroll(document, headerScrolled);
    }

    // ========== Mobile nav toggle ==========
    on("click", ".mobile-nav-toggle", function (e) {
      select("#navbar").classList.toggle("navbar-mobile");
      this.classList.toggle("icofont-navigation-menu");
      this.classList.toggle("icofont-close");
    });

    // ========== Mobile nav dropdowns activate ==========
    on(
      "click",
      ".navbar .dropdown > a",
      function (e) {
        if (select("#navbar").classList.contains("navbar-mobile")) {
          e.preventDefault();
          this.nextElementSibling.classList.toggle("dropdown-active");
        }
      },
      true
    );

    // ========== Scroll with ofset on page load with hash links in the url ==========
    window.addEventListener("load", () => {
      if (window.location.hash) {
        if (select(window.location.hash)) {
          scrollto(window.location.hash);
        }
      }
    });
  });

  return (
    <div className="App">
      <ScrollToTop>
        <Header />

        <Routes>
          <Route path={WebRoutes.HOME} element={<Home />} />
          <Route path={WebRoutes.PRODUCT} element={<Products />} />
          <Route path={WebRoutes.SERVICES} element={<Services />} />
          <Route path={WebRoutes.ABOUTUS} element={<AboutUs />} />
          <Route path={WebRoutes.CAREER} element={<Careers />} />
          <Route path={WebRoutes.OPENPOSITION} element={<OpenPositions />} />
          <Route path={WebRoutes.JOBDESCRIPTION} element={<JobDescription />} />
          <Route path={WebRoutes.CONTACTUS} element={<ContactUs />} />
          <Route path={WebRoutes.PRIVACY_POLICY} element={<PrivacyPolicy />} />
          <Route path={WebRoutes.TERMS_CONDITIONS} element={<TermConditions />} />
          <Route path={`${WebRoutes.SHARE_JOB}/:id`} element={<JobShare />} />

        </Routes>
        <Footer />
      </ScrollToTop>
    </div>
  );
}

export default App;
