import axios from "axios";
import { JOBSAPI, SHARED_RESUME, JOB_APPLY, SEARCH_API, JOB_DETAIL } from "../constant";
import { getCommonApiHeader } from "../../utils/util";



export const jobs = (data) => {
    return (dispatch, getState) => {
        dispatch(getJobDataRequest());
        axios
            .get(JOBSAPI, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                dispatch(getJobDataRespond(response?.data));
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const resumePostForm = (data) => {
    return (dispatch, getState) => {
        dispatch(getJobDataRequest());
        axios
            .post(SHARED_RESUME, data, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                if (response) {
                    dispatch(handleSuccess({ message: response.data.message }));
                }
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const appyJobAPI = (data) => {
    return (dispatch, getState) => {
        dispatch(getJobDataRequest());
        axios
            .post(JOB_APPLY, data, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                if (response) {
                    dispatch(handleSuccess({ message: response.data.message }));
                }
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const searchJobAPI = (data) => {
    return (dispatch, getState) => {
        dispatch(getJobDataRequest());
        axios
            .post(SEARCH_API, data, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                if (response) {
                    dispatch(getSearchDataRespond(response?.data));
                }
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}

export const jobDetailsAPI = (data) => {
    return (dispatch, getState) => {
        dispatch(getJobDataRequest());
        axios
            .get(JOB_DETAIL + '' + data, {
                headers: {
                    ...getCommonApiHeader(),
                },
            })
            .then((response) => {
                if (response) {
                    dispatch(getJOBDETAILSDataRespond(response?.data));
                }
            }).catch(err => {
                dispatch(handleError(err));
            });
    };
}


export const getJobDataRequest = data => {
    return {
        type: "Job_Data_REQUESTED",
    };
};

export const getJobDataRespond = data => {
    return {
        type: "Job_DATA_RESPONSE",
        data: data,
    };
};

export const getSearchDataRespond = data => {
    return {
        type: "SEARCH_JOB_DATA_RESPONSE",
        data: data,
    };
};
export const getJOBDETAILSDataRespond = data => {
    return {
        type: "JOB_DETAILS_DATA_RESPONSE",
        data: data,
    };
}

export const handleError = error => {
    return {
        type: "Job_ERROR",
        error,
    };
};

export const handleSuccess = (data) => {
    return {
        type: "SHOW_TOAST",
        data,
        toastType: "success",
    };
};

