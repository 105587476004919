import React, { useEffect, useState } from "react";
import DocumentMeta from "react-document-meta";
import { connect } from "react-redux";
import { product } from "../../redux/action/product";
import { IMAGE_BASE_URL } from "../../redux/constant";
import { parseHtml } from "../../utils/util";

const Products = ({ product, productData }) => {
  useEffect(() => {
    product();
  }, []);

  const meta = {
    title:
      "Zee News, Zee Business, Wion, DNA Sites - Digital Products @IDPL - IndiaDotCom Digital Pvt Ltd",
    description:
      "IDPL - IndiaDotcom Digital Pvt Ltd (formerly Zee Digital) is India’s one of the largest Digital Media Network with 32 Digital Properties. IDPL Digital Products are amongst the fastest-growing websites including Zee News, Zee Business, Wion, DNA and more.",
    meta: {
      name: {
        keywords:
          "digital news, online advertising, digital marketing, digital products, Zee Digital, Zee News, Zee Business, Wion, DNA, IDPL, IndiaDotCom Digital Pvt Ltd",
      },
    },
  };

  return (
    <DocumentMeta {...meta}>
      {/* <!-- =============== Hero Section Start =============== --> */}
      <section className="hero-section-3">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 col-sm-12 mx-auto">
              <h1 className="hero-title">
                <span>
                  India's Largest Digital Media And Infotainment Network
                </span>
              </h1>
            </div>
            <div className="col-md-7 mx-auto">
              <div className="hero-sub-title">
                More than 15,000,000 people around the world interact with our
                products every day
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <!-- =============== Hero Section End =============== --> */}

      {/* <!-- =============== Product Section Start =============== --> */}
      <section className="container product-section">
        <div className="row g-4">
          {productData.data &&
            productData.data.map((item) => (
              <div className="col-lg-4 col-md-4 col-sm-12">
                <div className={`channel-card ${item.theme && item.theme.color_class}`}>
                  <img
                    src={item.logo}
                    alt="Channel-Logo"
                    className="channel-logo"
                  />
                  <p>{parseHtml(item.description)}</p>

                  <div className="store-rating">
                    {item && item.play_store &&
                      <a className="web-store"
                        href={item.play_store}
                        target="_blank"
                      >
                        <img
                          src={`${IMAGE_BASE_URL}/icons/play-store.svg`}
                          alt="play-store"
                        />

                        <div>
                          <span>Play Store</span>
                          {item && item.play_store_value &&
                            <b>{item.play_store_value}</b>
                          }
                        </div>

                      </a>
                    }
                    {item && item.app_store &&
                      <a
                        className="web-store"
                        href={item.app_store}
                        target="_blank"
                      >
                        <img
                          src={`${IMAGE_BASE_URL}/icons/app-store.svg`}
                          alt="app-store"
                        />

                        <div>
                          <span>App Store</span>
                          {item && item.app_store_value &&
                            <b>{item.app_store_value}</b>
                          }
                        </div>

                      </a>
                    }
                  </div>

                </div>
              </div>
            ))}
        </div>
      </section>
      {/* <!-- =============== Product Section End =============== --> */}
    </DocumentMeta>
  );
};

const mapStateToProps = (state) => {
  const { ProductReducer } = state;
  const { productData } = ProductReducer;
  return {
    productData: ProductReducer.productData,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    product: () => dispatch(product()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Products);
