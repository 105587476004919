
import React, { useEffect, useState } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
import { IMAGE_BASE_URL } from "../../redux/constant";
import { WebRoutes } from "../../route";

function Header() {
  const [pathName, setPathName] = useState(false);
  const location = useLocation();
  const navigate = useNavigate();


  const headerClass =
    location.pathname == "/openPositions" ||
      location.pathname == "/jobDescription"
      ? "fixed-top d-flex align-items-center header-white"
      : "fixed-top d-flex align-items-center";
  // const headerClass = document.body;
  // if (
  //   location.pathname == "/login" ||
  //   location.pathname == "/user/about" ||
  //   location.pathname == "/user/contact_us" ||
  //   location.pathname == "/user/faq" ||
  //   location.pathname == "/user/privacy_policy" ||
  //   location.pathname == "/user/T&C"
  // ) {
  //   body_tag.classList.add("mt-0");
  // } else {
  //   body_tag.classList.remove("mt-0");
  // }

  // useEffect(() => {
  //   const metaData = document.createElement("meta");
  //   metaData.name = "description";
  //   metaData.content = "Handling requests in React";
  //   document.head.appendChild(metaData);
  // });

  const handleNavToggle = () => {
    if (window.innerWidth > 576) return false;
    document.querySelector("#navbar").classList.toggle("navbar-mobile");
    handleIconToggle();
  };
  const handleIconToggle = () => {
    document
      .querySelector(".mobile-nav-toggle")
      .classList.toggle("flaticon-menu");
    document
      .querySelector(".mobile-nav-toggle")
      .classList.toggle("flaticon-close");
  };

  return (
    <header id="header" className={headerClass}>
      <div className="container d-flex align-items-center">
        <Link className="logo me-auto" to="/">
          <img src={`${IMAGE_BASE_URL}/logo.svg`} alt="IDPL-Logo" />
        </Link>

        <nav id="navbar" className="navbar order-last order-lg-0">
          <ul>
            <li>

              <Link
                className="nav-link"
                to={WebRoutes.PRODUCT}
                onClick={() => {
                  handleNavToggle();
                }}
              >
                Products
              </Link>
            </li>
            <li>

              <Link
                className="nav-link"
                to={WebRoutes.SERVICES}
                onClick={() => {
                  handleNavToggle();
                }}
              >
                Services
              </Link>
            </li>
            <li>
              <Link
                className="nav-link"
                to={WebRoutes.ABOUTUS}
                onClick={() => {
                  handleNavToggle();
                }}
              >
                About us
              </Link>
            </li>
            <li>

              <Link
                className="nav-link"
                to={WebRoutes.CAREER}
                onClick={() => {
                  handleNavToggle();
                }}
              >
                Careers
              </Link>
            </li>
          </ul>
          <i
            className="flaticon-menu mobile-nav-toggle"
            onClick={() => {
              handleIconToggle();
            }}
          ></i>
        </nav>
      </div>
    </header>
  );
}

export default Header;
